export class context_variables {
    private global: any;
    private values: [];
    private user_attribute:any;
    private user_attribute_key:any;
  

    /**
     * Getter $global
     * @return {any}
     */
	public get $global(): any {
		return this.global;
	}

    /**
     * Getter $values
     * @return {[]}
     */
	public get $values(): [] {
		return this.values;
    }
    
    /**
     * Getter $global
     * @return {any}
     */
	public get $user_attribute(): any {
		return this.user_attribute;
     }

      /**
     * Getter $global
     * @return {[]}
     */
	public get $user_attribute_key(): [] {
		return this.user_attribute_key;
	}


   
    /**
     * Setter $global
     * @param {any} value
     */
	public set $global(value: any) {
		this.global = value;
	}

    /**
     * Setter $values
     * @param {[]} value
     */
	public set $values(value: []) {
		this.values = value;
    }
    

    /**
     * Setter $global
     * @param {any} value
     */
	public set $user_attribute(value: any) {
		this.user_attribute = value;
	}
  
     /**
     * Setter $global
     * @param {[]]} value
     */
	public set $user_attribute_key(value: []) {
		this.user_attribute_key = value;
	}
  
 
 
}
