import {
  ComponentRef,
  ComponentFactoryResolver,
  Injectable,
  ApplicationRef,
  Inject,
  ReflectiveInjector,
  ViewContainerRef
} from '@angular/core';
import { jsPlumb } from 'jsplumb';
import { NodeComponent, Node } from './node/node.component';
import { componentRefresh } from '@angular/core/src/render3/instructions';


@Injectable()
export class NodeService {


  private rootViewContainer: any;

  //jsPlumbInstance = jsPlumb.getInstance();
  private jsPlumbInstance : any;


  constructor(private factoryResolver: ComponentFactoryResolver,private appRef: ApplicationRef) {

   }

  public setContainerReset(view, id) {
    if(id != ""){
      this.jsPlumbInstance=jsPlumb.getInstance();
      this.jsPlumbInstance.remove(id);

    }else if(id == ""){
      this.jsPlumbInstance=jsPlumb.getInstance();
    }
    // this.jsPlumbInstance.remove(id);
    // this.jsPlumbInstance=jsPlumb.getInstance();
    this.jsPlumbInstance.restoreDefaults();

    this.setRootViewContainerRef(view)
  }

  private setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  public addDynamicNode(node: Node) {
    //this.jsPlumbInstance=jsPlumb.getInstance();
    const factory = this.factoryResolver.resolveComponentFactory(NodeComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);
    (<any>component.instance).node = node;
    (<any>component.instance).jsPlumbInstance = this.jsPlumbInstance
    this.rootViewContainer.insert(component.hostView);
  }

  // updateConnection(connection){
  //   connection.forEach(conn =>{
  //     if(conn.x)){

  //     }

  //   }
  // }

  addConnection(connection,branching) {
    console.log(branching);
    connection.forEach(arrayConnection => {
        let branchingLabel=null;
      if(arrayConnection.x.includes('bottom')){
        (arrayConnection.y).forEach((target,index) => {

          if(branching.length > 0){
          branching.forEach(element => {

            if(target == element.value+"_top"){
              branchingLabel=element.key;
            }
          });
          }
          if(branchingLabel != null){
          this.jsPlumbInstance.connect({
            uuids: [arrayConnection.x, target],
            detachable: true,
            overlays: [
            ["Label", {
              location:0.5,
                label:branchingLabel,
                cssClass:"conn-label-class",
                visible: true,
               }
            ]
          ]
          });
          }else{
            this.jsPlumbInstance.connect({
            uuids: [arrayConnection.x, target],
            detachable: true,});
          }
          branchingLabel=null;
        })
      }else if(arrayConnection.x.includes('Right')){
        this.jsPlumbInstance.connect({
          uuids: [arrayConnection.x, arrayConnection.y],
          detachable: true,
          overlays: [
            ["Label", {
              location:0.5,
                label:"False",
                cssClass:"conn-label-class",
                visible: true,
               }
            ]
          ]

        });
      }else  if(arrayConnection.x.includes('Left')){
        this.jsPlumbInstance.connect({
          uuids: [arrayConnection.x, arrayConnection.y],
          detachable: true,
          overlays: [
            ["Label", {
              location:0.5,
                label:"True",
                cssClass:"conn-label-class",
                visible: true,
               }
            ]
          ]
        });
      }
    });


  }


  public clear() {
    this.rootViewContainer.clear();
    this.jsPlumbInstance.deleteEveryEndpoint();
  }

  public  refreshInstance(view){
    this.clear();
    this.jsPlumbInstance=jsPlumb.getInstance();
    this.jsPlumbInstance.deleteEveryEndpoint();
    view.clear();
    this.setRootViewContainerRef(this.rootViewContainer);
    return this.rootViewContainer;

  }

 public removeNode(id,view){
  this.clear();
  //***comment for testing (do not remove)**
   // this.jsPlumbInstance.remove(id);
    //this.jsPlumbInstance.deleteEndpoint(id);
  //***comment for testing (do not remove)**

    this.jsPlumbInstance=jsPlumb.getInstance();
    this.jsPlumbInstance.reset();
    this.jsPlumbInstance.restoreDefaults();
    view.clear();
    this.setRootViewContainerRef(this.rootViewContainer);
    let deleteNode=true;
    return deleteNode;
  }

  public updateRef(node: Node, uniqueCount){
    //this.removeNode(node.node_id,view);
    console.log(uniqueCount);
    const exampleDropOptions = {
      tolerance: 'touch',
      hoverClass: 'dropHover',
      activeClass: 'dragActive'
    };
    let bottomEndpoint1 = {
      endpoint: ['Dot', { radius: 7 ,cssClass : 'top-class'}],
      paintStyle: { fill: '#4772dc' },
      isSource: true,
      scope: 'jsPlumb_DefaultScope',
      connectorStyle: { stroke: '#4772dc', strokeWidth: 2 },
      connector: ['Flowchart', {  curviness: 1, stub: 10 ,cornerRadius: 5,}],
      maxConnections: uniqueCount,
      isTarget: false,
      connectorOverlays: [['Arrow', { location: 1 }]],
      dropOptions: exampleDropOptions

    };

    this.jsPlumbInstance.addEndpoint(node.node_id, { anchor: 'Bottom', uuid: node.node_id + '_bottom'}, bottomEndpoint1)
  }


}
