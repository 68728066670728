export const USER_PROPERTY : string[] = ["user property" , "context variable"];
export const OPERATOR_TYPE : string[] = ["AND","OR"];
export const MINUTE_SECOND_ARRAY : number[] = [0,1,2,3,4,5,6,7,8,9,10,
11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,
28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,
43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59];
export const HOUR_ARRAY: number[] = [0,1,2,3,4,5,6,7,8,9,10,11,12];
export const ORGANISATION_ID : number = 3;
export const USER_INPUT_ARRAY : any[] = [{ name: "Full Name", value: "Full Name" }, { name: "Mobile Number", value: "Mobile Number" }, { name: "Email", value: "Email" }, { name: "Address", value: "Address" }, { name: "Password", value: "Password" }, { name: "Dropdown", value: "Dropdown" }, { name: "Button", value: "Buttons" }, { name: "Free Text", value: "Free Text" }, { name: "File Upload", value: "File Upload" },{ name: "Regex", value: "Regex"},{name:"Date",value:"Date"},{name:"Time", value:"Time"}];
export const USER_INPUT_ARRAY_FOR_WTAPP:any[]=[{ name: "Full Name", value: "Full Name" }, { name: "Mobile Number", value: "Mobile Number" }, { name: "Email", value: "Email" }, { name: "Address", value: "Address" }, { name: "Password", value: "Password" },  { name: "Free Text", value: "Free Text" },{ name: "Regex", value: "Regex"},{name:"Date",value:"Date"},{name:"Time", value:"Time"}];

export const USER_ATTRIBUTE_ARRAY : string[] = ["Name","First-Name"," Last-Name", "Phone-Number","Mobile-Number","Email-Address","City","Country","Address"];
export const GOAL_UNIT_ARRAY : string[] = ["UNIT", "INR", "USD", "STRING"]

export const node_type_map: object ={"ACTION":"Action","ENTRY":"Intent","CONDITIONAL":"Condition",};
export const node_sub_type_map: object ={"INCOMING_MSG":"Incoming message","EVENT":"Event","USER_INPUT":"User input","IF_ELSE":"If else","OUTBOUND":"Reply","API" : "Api call","WEBHOOK":"Webhook","STAR RATING":"Star rating","STOP": "Stop","LOOP": "Loop","WORKFLOW_TRIGGER":"Workflow trigger","DEPARTMENT_SWITCHER":"Department switcher","WAIT_UNTIL":"Wait until","DELAY":"Delay"};