import { link } from './link';

export class links {

 /**
     * Getter $x
     * @return {any}
     */
	public get $x(): any {
		return this.x;
	}

    /**
     * Getter $y
     * @return {[]}
     */
	public get $y(): [] {
		return this.y;
	}

    /**
     * Setter $x
     * @param {any} value
     */
	public set $x(value: any) {
		this.x = value;
	}

    /**
     * Setter $y
     * @param {[]} value
     */
	public set $y(value: []) {
		this.y = value;
	}
  private x: any;
 
  private y: [];
  
}
