 export class nextNode{

    private node_id: any;
    private yes_node_id : any;
    private  no_node_id : any;
    private branch_nodes_id:any;


    public get $branch_nodes_id():any{
         return this.branch_nodes_id;
    }

	public set $branch_nodes_id(value: any) {
		this.branch_nodes_id = value;
	}


    /**
     * Getter $node_id
     * @return {any}
     */
	public get $node_id(): any {
		return this.node_id;
	}

    /**
     * Getter $yes_node_id
     * @return {any}
     */
	public get $yes_node_id(): any {
		return this.yes_node_id;
	}

    /**
     * Getter $no_node_id
     * @return {any}
     */
	public get $no_node_id(): any {
		return this.no_node_id;
	}

    /**
     * Setter $node_id
     * @param {any} value
     */
	public set $node_id(value: any) {
		this.node_id = value;
	}

    /**
     * Setter $yes_node_id
     * @param {any} value
     */
	public set $yes_node_id(value: any) {
		this.yes_node_id = value;
	}

    /**
     * Setter $no_node_id
     * @param {any} value
     */
	public set $no_node_id(value: any) {
		this.no_node_id = value;
	}
  


}