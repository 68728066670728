import { NgModule } from '@angular/core'; 
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router'; 
import { BotContainerComponent } from './bot-container/bot-container.component';
import { ChatbotListComponent } from './chatbot-list/chatbot-list.component';
import { AuthGuard } from './guard/auth.guard';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

const routes: Routes = [

 {path: '', component: PagenotfoundComponent},
 {path: 'workflow/:id', component: BotContainerComponent,canActivate: [AuthGuard]},
 {path: 'error', component: PagenotfoundComponent},
 {path: ':orgId', component: ChatbotListComponent, canActivate: [AuthGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
