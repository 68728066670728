


export class checkBranching{
static  getBranchingNodes(nodeJson:any){
if(nodeJson && nodeJson.node_sub_type =="USER_INPUT"){
    if(nodeJson.node_data.inbound){
        if((nodeJson.node_data.inbound.input.type=="Dropdown" || nodeJson.node_data.inbound.input.type=="Buttons") && nodeJson.node_data.inbound.input.branching 
        && nodeJson.node_data.inbound.input.mutli_select==false && nodeJson.node_data.inbound.input.selection_option == "MANUAL"){
            let count= nodeJson.node_data.inbound.input.options.length;
            let inputOption=nodeJson.node_data.inbound.input.options;
           return  checkBranching.getUniqueListBy(inputOption,'value'); ;


        }
        return null;
    }
return null;

}


}
static getUniqueListBy(inputOption, key) {
        return [...new Map(inputOption.map(item => [item[key], item])).values()]
    }



}

