import { Data } from './Data';
import { context_variables } from './context_variables';
import { links } from './links';
import { input } from './input';
import { actions } from './actions';
import { NodeData } from './nodeData';

export class NodeJson {
  private id:any;
  private bot_id: any;
  private bot_name: any;
  private node_id: any;
  private node_name: any;
  private node_type: any;
  private node_sub_type: any;
  private node_data : NodeData;
  private goal_name: any;
  private goal_value: any;
  private goal_unit: any;

    /**
     * Getter $flag
     * @return {any}
     */
	public get $flag(): any {
		return this.flag;
	}

    /**
     * Setter $flag
     * @param {any} value
     */
	public set $flag(value: any) {
		this.flag = value;
	}
  private flag:any;

    /**
     * Getter $id
     * @return {any}
     */
	public get $id(): any {
		return this.id;
	}

    /**
     * Getter $bot_id
     * @return {any}
     */
	public get $bot_id(): any {
		return this.bot_id;
	}

    /**
     * Getter $bot_name
     * @return {any}
     */
	public get $bot_name(): any {
		return this.bot_name;
	}

    /**
     * Getter $node_id
     * @return {any}
     */
	public get $node_id(): any {
		return this.node_id;
	}

    /**
     * Getter $node_name
     * @return {any}
     */
	public get $node_name(): any {
		return this.node_name;
	}

    /**
     * Getter $node_type
     * @return {any}
     */
	public get $node_type(): any {
		return this.node_type;
	}

    /**
     * Getter $node_sub_type
     * @return {any}
     */
	public get $node_sub_type(): any {
		return this.node_sub_type;
	}

    /**
     * Getter $node_data
     * @return {NodeData}
     */
	public get $node_data(): NodeData {
		return this.node_data;
     }
     
     /**
     * Getter $goal_name
     * @return {any}
     */
	public get $goal_name(): any {
		return this.goal_name;
     }
     
     /**
     * Getter $goal_value
     * @return {any}
     */
	public get $goal_value(): any {
		return this.goal_value;
     }
     
     /**
     * Getter $goal_unit
     * @return {any}
     */
	public get $goal_unit(): any {
		return this.goal_unit;
	}

    /**
     * Setter $id
     * @param {any} value
     */
	public set $id(value: any) {
		this.id = value;
	}

    /**
     * Setter $bot_id
     * @param {any} value
     */
	public set $bot_id(value: any) {
		this.bot_id = value;
	}

    /**
     * Setter $bot_name
     * @param {any} value
     */
	public set $bot_name(value: any) {
		this.bot_name = value;
	}

    /**
     * Setter $node_id
     * @param {any} value
     */
	public set $node_id(value: any) {
		this.node_id = value;
	}

    /**
     * Setter $node_name
     * @param {any} value
     */
	public set $node_name(value: any) {
		this.node_name = value;
	}

    /**
     * Setter $node_type
     * @param {any} value
     */
	public set $node_type(value: any) {
		this.node_type = value;
	}

    /**
     * Setter $node_sub_type
     * @param {any} value
     */
	public set $node_sub_type(value: any) {
		this.node_sub_type = value;
	}

    /**
     * Setter $node_data
     * @param {NodeData} value
     */
	public set $node_data(value: NodeData) {
		this.node_data = value;
     }
     
     /**
     * Setter $goal_name
     * @param {any} value
     */
	public set $goal_name(value: any) {
		this.goal_name = value;
     }
     
     /**
     * Setter $goal_value
     * @param {any} value
     */
	public set $goal_value(value: any) {
		this.goal_value = value;
     }
     
     /**
     * Setter $goal_unit
     * @param {any} value
     */
	public set $goal_unit(value: any) {
		this.goal_unit = value;
	}

}
  




