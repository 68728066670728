
import { entryArgs } from './entryArgs';
import { DelayTime } from './delayTime';

export class Data {
     private EntryArgs :entryArgs;
     private outbound : Array<Object>;
     private waitForUserResponse:boolean;
     private inbound: Map<String, Object>
     private endPointArgsMap:Map<String, Object>
     private workflowTriggerArgs:Map<String, Object> ;
     private deptSwitcherArgs: Map<String, Object> ;
     private delayArgs:DelayTime;
     private waitUntilArgs: {} ;
     private conditionArgs:{} ;
     private counterValue : number;

    /**
     * Getter $counter_value
     * @return {number}
     */
	public get $counterValue(): number {
		return this.counterValue;
	}

    /**
     * Setter $counter_value
     * @param {number} value
     */
	public set $counterValue(value: number) {
		this.counterValue = value;
	}
     

    /**
     * Getter $conditionArgs
     * @return {{} }
     */
	public get $conditionArgs(): {}  {
		return this.conditionArgs;
	}

    /**
     * Setter $conditionArgs
     * @param {{} } value
     */
	public set $conditionArgs(value: {} ) {
		this.conditionArgs = value;
	}


    /**
     * Getter $waitUntilArgs
     * @return {{} }
     */
	public get $waitUntilArgs(): {}  {
		return this.waitUntilArgs;
	}

    /**
     * Setter $waitUntilArgs
     * @param {{} } value
     */
	public set $waitUntilArgs(value: {} ) {
		this.waitUntilArgs = value;
	}



      /**
     * Getter $inbound
     * @return {Map<String, Object> }
     */
	public get $inbound(): Map<String, Object>  {
		return this.inbound;
     }

/**
     * Setter $inbound
     * @param {Map<String, Object> } value
     */
    public set $inbound(value: Map<String, Object> ) {
     this.inbound = value;
}



 /**
     * Getter $endPointArgsMap
     * @return {Map<String, Object> }
     */
    public get $endPointArgsMap(): Map<String, Object>  {
     return this.endPointArgsMap;
}

     /**
     * Setter $endPointArgsMap
     * @param {Map<String, Object> } value
     */
     public set $endPointArgsMap(value: Map<String, Object> ) {
     this.endPointArgsMap = value;
     }

    /**
     * Getter $delayArgs
     * @return {DelayTime}
     */
	public get $delayArgs(): DelayTime {
		return this.delayArgs;
	}

    /**
     * Setter $delayArgs
     * @param {DelayTime} value
     */
	public set $delayArgs(value: DelayTime) {
		this.delayArgs = value;
	}


    /**
     * Getter $EntryArgs
     * @return {entryArgs}
     */
	public get $EntryArgs(): entryArgs {
		return this.EntryArgs;
	}

    /**
     * Getter $outbound
     * @return {Array<Object>}
     */
	public get $outbound(): Array<Object> {
		return this.outbound;
	}

    /**
     * Getter $waitForUserResponse
     * @return {boolean}
     */
	public get $waitForUserResponse(): boolean {
		return this.waitForUserResponse;
	}

    /**
     * Getter $workflowTriggerArgs
     * @return {Map<String, Object> }
     */
	public get $workflowTriggerArgs(): Map<String, Object>  {
		return this.workflowTriggerArgs;
	}

    /**
     * Getter $deptSwitcherArgs
     * @return {Map<String, Object> }
     */
	public get $deptSwitcherArgs(): Map<String, Object>  {
		return this.deptSwitcherArgs;
	}





    /**
     * Setter $EntryArgs
     * @param {entryArgs} value
     */
	public set $EntryArgs(value: entryArgs) {
		this.EntryArgs = value;
	}

    /**
     * Setter $outbound
     * @param {Array<Object>} value
     */
	public set $outbound(value: Array<Object>) {
		this.outbound = value;
	}

    /**
     * Setter $waitForUserResponse
     * @param {boolean} value
     */
	public set $waitForUserResponse(value: boolean) {
		this.waitForUserResponse = value;
	}

    /**
     * Setter $workflowTriggerArgs
     * @param {Map<String, Object> } value
     */
	public set $workflowTriggerArgs(value: Map<String, Object> ) {
		this.workflowTriggerArgs = value;
	}

    /**
     * Setter $deptSwitcherArgs
     * @param {Map<String, Object> } value
     */
	public set $deptSwitcherArgs(value: Map<String, Object> ) {
		this.deptSwitcherArgs = value;
	}



   }