import { Component, OnInit, NgModule,TemplateRef  , Inject, ElementRef, Renderer} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ChatbotListService } from '../services/chatbotlist/chatbot-list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { BotdataService } from '../services/botdata.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import {ORGANISATION_ID} from '../constants/constantVar'
import { HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-chatbot-list',
  templateUrl: './chatbot-list.component.html',
  styleUrls: ['./chatbot-list.component.css'],
  providers : [DatePipe]
})
export class ChatbotListComponent implements OnInit {
  minDate: Date;
  modalRef: BsModalRef;
  open_it: boolean = false;
  open_edit:boolean = false;
  isLoading: boolean = false;
  edit_info:any;
  chatbotForm: FormGroup;
  chatbotEditForm: FormGroup;
  submitted = false;
  submitted1 = false;
  chat_id:any;
  user_count:any;
  invalidMoment:any;
  startDate:any;
  endDate:any;
  startDatemin : any = this.setTodayDateWithZeroSec(new Date());
  endDatemin : any = this.setTodayDateWithZeroSec(new Date());
  todayDate : any;
  updateStartDateMin:any;
  startInvalidError:boolean=false;
  endInvalidError:boolean=false;
  updateStartInvalidError:boolean = false;
  updateEndInvalidError:boolean = false;
  startDateError:boolean = false;
  updateStartDateError:boolean = false;
  todayStartDateError : boolean = false;
  todayEndDateError : boolean = false;
  updateTodayStartDateError : boolean = false;
  updateTodayEndDateError : boolean = false;
  botList: any;
  nodes:[];
  result:any;
  data:any;
  org_id:any; 
  radioSelected:string;
  radioSelectedString:string; 
  radioSel:any; 
  organizationId:any;
  ctrlDown = false;

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  constructor(private modalService: BsModalService, public chatbotservice:ChatbotListService, 
      private formBuilder: FormBuilder,private router:Router 
      ,private botService:BotdataService,private toastr: ToastrService , 
      private dtpicker : DatePipe , private activatedRoute : ActivatedRoute,
      private cookieService : CookieService , private el : ElementRef , private render : Renderer) {

             this.organizationId =  this.activatedRoute.snapshot.params["orgId"];
             sessionStorage.setItem("botifier-workflow-org-id" , this.organizationId);
             this.minDate = new Date(); 
             this.minDate.setDate(this.minDate.getDate());
             this.navigateToNodePage();
  }
  
  ngOnInit() {

    this.chatbotForm = this.formBuilder.group({
      name: ['', Validators.required],
      // channel_type: ['', Validators.required],
      organization_department_id: ['', Validators.required],
      organization_id: ['', Validators.required],
      multiple_allow: [false],
      start_at: [""],
      end_at: [""],
      test_mode_enabled:[false],
      enable_customer_handle_regex:[''],
    });

    this.chatbotEditForm = this.formBuilder.group({
      name: ['', Validators.required],
      // channel_type: ['', Validators.required],
      organization_department_id: ['', Validators.required], 
      organization_id: [''],
      multiple_allow: [false],
      start_at: [""],
      end_at: [""],
      id: [''],
      test_mode_enabled:[false],
      enable_customer_handle_regex:[''],
    });
}

navigateToNodePage(){
  let botId = sessionStorage.getItem("botifier-workflow-bot-id");
  if(sessionStorage.getItem("botifier-workflow-bot-id")){
    this.router.navigate(['workflow', botId]);
  }else{
     //this.validateCookie(); 
     this.chatbotlist();
 
  }
}
EnableCustomerHandleRegexCreate(event)
{  
  if(event.target.checked)
 {
  this.chatbotForm.controls['enable_customer_handle_regex'].setValidators([Validators.required]);
  this.chatbotForm.get('enable_customer_handle_regex').updateValueAndValidity();
 }
 else
 {
  this.chatbotForm.get('enable_customer_handle_regex').clearValidators();
  this.chatbotForm.get('enable_customer_handle_regex').updateValueAndValidity();
 }
 
}
EnableCustomerHandleRegexUpdate(event)
{
 if(event.target.checked)
 {
  this.chatbotEditForm.controls['enable_customer_handle_regex'].setValidators([Validators.required]);
  this.chatbotEditForm.get('enable_customer_handle_regex').updateValueAndValidity();
 }
 else{
  this.chatbotEditForm.get('enable_customer_handle_regex').clearValidators();
  this.chatbotEditForm.get('enable_customer_handle_regex').updateValueAndValidity();
 }
}
inputRegexValidation()
{
  if(this.chatbotEditForm.value.test_mode_enabled==true )
  {
   this.chatbotEditForm.controls['enable_customer_handle_regex'].setValidators([Validators.required]);
   this.chatbotEditForm.get('enable_customer_handle_regex').updateValueAndValidity();
  }
  if(this.chatbotEditForm.value.test_mode_enabled==false) 
  {
  this.chatbotEditForm.get('enable_customer_handle_regex').clearValidators();
  this.chatbotEditForm.get('enable_customer_handle_regex').updateValueAndValidity();

  }
}

validateCookie(){
    this.chatbotservice.isCookieValAvail.subscribe((val) =>{
      //if(val == false || val == "" || val == null || val == undefined){
      if(!val){  
        const cookieVal = this.cookieService.get("wfb-uid");
          if(cookieVal != null && cookieVal != ""){
            var cookieObj = {
              "wfb-uid" : cookieVal
            }
            this.chatbotservice.validateCookie(cookieObj).subscribe((resp)=>{
              if (resp.statusCode=="400") {
                  this.router.navigate(["error"] , {skipLocationChange : true});
              }else if(resp.statusCode=="200"){
                this.chatbotservice.setCookieVal(true);
                this.chatbotlist();     
              }
            }) 
          }else{
            this.router.navigate(["error"] , {skipLocationChange : true});
          }
      }else{
            this.chatbotlist();
      } 
    })
}


  chatbotlist() {
    this.isLoading = true;
    this.chatbotservice.chatBotlist(this.organizationId).subscribe(
      (response) => {
        if (response.statusCode == "200") {
             this.isLoading = false;
             this.botList = response.result; 
             this.orgId();
          }
      },
      (error: Response) => {
        console.log("Error", error);
      }
    );
  }


  addTenSecInTime(date){
    if(date != null && date != undefined && date != ""){
        let time = date.getTime();
        let date1 = (time+10000);
        date.setTime(date1)
        return date;
    }else {
        return null;
    }     
  }
  
  subTenSecInTime(date){
    if(date != null && date != undefined && date != ""){
        let time = date.getTime();
        let date1 = (time-10000);
        date.setTime(date1)
        return date;
    }else {
        return null;
    }     
  }
  
  
  setTodayDateWithZeroSec(today){
    if(today != null && today != undefined && today != ""){
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(),today.getMinutes()-1, 0);
      return today;
    }else {
      return null;
    }    
  }


  orgId(){
    this.chatbotservice.orgId(this.organizationId).subscribe(
      (response) => {
        if (response.status=="200") { 
          this.org_id=response.result; 
        }
      },
      (error: Response) => {
      });
  }
  AssignChannnelType(organization_department_id)
  {
    let filter = this.org_id.filter(x => x.id == organization_department_id)
    this.chatbotForm.controls['channel_type'].setValue(+(filter[0].id)); 
    this.chatbotEditForm.controls['channel_type'].setValue(+(filter[0].id)); 
  }

  opendelModal(template: TemplateRef<any>,id) {
    this.modalRef = this.modalService.show(template,this.config);
    this.chat_id=id; 
    this.chatBotUser(this.chat_id); 
  }

  chatBotUser(id){
    this.chatbotservice.getchatBotList(id , this.organizationId).subscribe(
      (response) => {
        if (response.statusCode=="200") { 
          this.user_count=response.result; 
        }
      },
      (error: Response) => {
      });
  }

  openpauseModal(template: TemplateRef<any>,id , type , noOfNodes) {
    if(type == 'resume' && noOfNodes == 0){
      this.toastr.success('Workflow can not be resumed . There is no node present in this workflow');
    }else if(noOfNodes > 0){
        this.modalRef = this.modalService.show(template,this.config);
        this.chat_id=id;
        this.chatBotUser(this.chat_id); 
    }
  }

  confirmPause(chat_id): void {  
    this.chatbotservice.pauseById(chat_id)
    .subscribe(data  => {    
      this.toastr.success('Workflow has been paused successfully');
      this.chatbotlist();
      },
      error  => { 
      this.toastr.error('Something went wrong, please try again');
      });
    this.modalRef.hide();
  }

  confirmResume(chat_id):void{
    this.chatbotservice.resumeById(chat_id).subscribe(data  => { 

      if (data.statusCode=="200") {
         this.toastr.success('Workflow has been resume successfully');
          this.chatbotlist();
        }else if (data.statusCode== 400){
          this.toastr.error(data["message"]);
        }
      },
      (error: Response) => {
        
      }
   
    );
     this.modalRef.hide();
  }
 
  declinePause(): void { 
    this.modalRef.hide();
  }
  // convenience getter for easy access to form fields
  get f() { return this.chatbotForm.controls; }
  get g() { return this.chatbotEditForm.controls; }



  checkDateFormat(type1 , type2){
    
    this.todayDate = this.setTodayDateWithZeroSec(new Date());

    if(type1 == 'NEW'){
      let sd = this.el.nativeElement.querySelector('.startDate').value;
      let ed = this.el.nativeElement.querySelector('.endDate').value;
      let startAt = this.chatbotForm.value.start_at;
      let endAt = this.chatbotForm.value.end_at;

      if(sd == "" || (Date.parse(startAt)) > Date.parse(this.todayDate.toString())){
        this.startDatemin = this.setTodayDateWithZeroSec(new Date());
      }else if((Date.parse(startAt)) < Date.parse(this.todayDate.toString())){
        this.startDatemin = this.setTodayDateWithZeroSec(startAt);
      }
     
      if(type2 == 'startAt'){
          if(sd != ""){
              if(startAt != null || startAt == ""){
                this.startInvalidError = false;
              }else if(startAt == null){
                this.startInvalidError = true;
                this.startDateError = false;
                this.todayStartDateError = false;
              }
          }else{
             this.clearStartErrorDiv();
          }
        }else if(type2 == 'endAt'){
          if(ed != ""){
              
            
              if(endAt != null || endAt == ""){
                this.endInvalidError = false;
              }else if(endAt == null){
                this.endInvalidError = true;
                this.todayEndDateError = false;
              }
          }else{
            this.clearEndErrorDiv();
          }   
        }

        if((Date.parse(this.chatbotForm.value.start_at)) < Date.parse(this.todayDate.toString())){
          this.startDateError = false;
          this.startInvalidError = false;
          this.todayStartDateError = true;
          //return;
        }else{
          this.todayStartDateError  = false;
        }

        if((Date.parse(this.chatbotForm.value.end_at)) < Date.parse(this.todayDate.toString())){
          this.endInvalidError = false;
          this.todayEndDateError = true;
          //return;
        }else{
          this.todayEndDateError  = false;
        }

        if(Date.parse(this.chatbotForm.value.start_at) > Date.parse(this.chatbotForm.value.end_at)){
          this.startDateError = true;
          this.startInvalidError = false;
          this.todayStartDateError = false;
          return;
        }else{
          this.startDateError = false;
        }

        

    }else if(type1 == 'UPDATED'){
 
      let sd = this.el.nativeElement.querySelector('.updateStartDate').value;
      let ed = this.el.nativeElement.querySelector('.updateEndDate').value;
      let startAt = this.chatbotEditForm.value.start_at;
      let endAt = this.chatbotEditForm.value.end_at;

      if(sd == "" || (Date.parse(startAt)) > Date.parse(this.todayDate.toString())){
        this.updateStartDateMin = this.setTodayDateWithZeroSec(new Date());
      }else if((Date.parse(startAt)) < Date.parse(this.todayDate.toString())){
        this.updateStartDateMin = this.setTodayDateWithZeroSec(startAt);
      }
    
      if(type2 == 'startAt'){
        if(sd != ""){
          
            if(startAt != null || startAt == ""){
              this.updateStartInvalidError = false;
            }else if(startAt == null){
              this.updateStartInvalidError = true;
              this.updateStartDateError = false;
            }
        }else{
           this.clearStartErrorDiv();
        }   
      }else if(type2 == 'endAt'){
        if(ed != ""){
           
            if(endAt != null || endAt == ""){
              this.updateEndInvalidError = false;
            }else if(endAt == null){
              this.updateEndInvalidError = true;
            }
        }else{
           this.clearEndErrorDiv();
        }   
      }


      if(Date.parse(this.chatbotEditForm.value.start_at) < Date.parse(this.todayDate.toString())){
        this.updateStartInvalidError = false;
        this.updateStartDateError = false;
        this.updateTodayStartDateError = true;
        //return;
      }else{
        this.updateTodayStartDateError  = false;
      }

      if(Date.parse(this.chatbotEditForm.value.end_at) < Date.parse(this.todayDate.toString())){
        this.updateEndInvalidError = false;
        this.updateTodayEndDateError = true;
        //return;
      }else{
        this.updateTodayEndDateError  = false;
      }

      if(Date.parse(this.chatbotEditForm.value.start_at) > Date.parse(this.chatbotEditForm.value.end_at)){
        this.updateStartDateError = true;
        this.updateStartInvalidError = false;
        this.updateTodayStartDateError = false;
      }else{
        this.updateStartDateError = false;
      }

    }
  }


  selectStartDate(status){
    if(status == 'NEW'){
      this.startDate = this.chatbotForm.value.start_at;
      this.endDate = this.chatbotForm.value.end_at;
        if(this.startDate != "" && this.startDate != undefined  && this.startDate != null){
            this.endDatemin = this.setTodayDateWithZeroSec(new Date(this.startDate));
        }else{
            this.endDatemin = this.setTodayDateWithZeroSec(new Date());
        }
    }else if(status == 'UPDATED'){  
      this.startDate = this.chatbotEditForm.value.start_at;        
      this.endDate = this.chatbotEditForm.value.end_at;
        if(this.startDate != "" &&  this.startDate != undefined  &&  this.startDate != null){
            this.endDatemin =  this.setTodayDateWithZeroSec(new Date(this.startDate));
        }else{
            this.endDatemin = this.setTodayDateWithZeroSec(new Date());
        } 
    }
}

onSubmit() {

  if(this.todayDate != undefined){
      if((Date.parse(this.chatbotForm.value.start_at)) < Date.parse(this.todayDate.toString())){
        this.startDateError = false;
        this.startInvalidError = false;
        this.todayStartDateError = true;
        return;
      }else{
        this.todayStartDateError  = false;
      }

      if((Date.parse(this.chatbotForm.value.end_at)) < Date.parse(this.todayDate.toString())){
        this.endInvalidError = false;
        this.todayEndDateError = true;
        return;
      }else{
        this.todayEndDateError  = false;
      }
  }

    if(Date.parse(this.chatbotForm.value.start_at) > Date.parse(this.chatbotForm.value.end_at)){
       this.startDateError = true;
       this.startInvalidError = false;
       this.todayStartDateError = false;
       return;
    }else{
       this.startDateError = false;
    }

    this.chatbotForm.controls['organization_id'].setValue(this.organizationId); 
    this.submitted = true;


     
    // stop here if form is invalid
    if (this.chatbotForm.invalid || this.startInvalidError == true || 
      this.endInvalidError == true) {
      return;
    }
    this.isLoading = true;
    this.chatbotservice.getchatBot(this.chatbotForm.value).subscribe(
      (response) => {
        if (response.statusCode=="200") {
          this.isLoading = false;
          this.toastr.success('Workflow has been created successfully');
          this.closeModal();
          let obj = response["result"];
          if(this.org_id){
            let filter = this.org_id.filter(x => x.id == obj.organization_department_id)
            // sessionStorage.setItem("channel_group_name" , filter[0].channel.group_name);
          }  
          obj["flag"] = false;
          obj["openEntryModal"] = true;
          sessionStorage.setItem("botifier-workflow-bot-id" , response["result"]["id"]);
          this.botService.showOtherTabs(obj);
          this.router.navigate(['workflow', response["result"]["id"]]);          
        }else if (response.statusCode== 400){
          this.toastr.error(response["message"]);
        }
      },
      (error: Response) => {
        this.isLoading = false;
        //this.toastr.error('Something went wrong, please try again');
      }); 
}

  showCanvas(chatbot){
    let obj = chatbot;
    if(this.org_id){
     let filter = this.org_id.filter(x => x.id == chatbot.organization_department_id)
    //  sessionStorage.setItem("channel_group_name" , filter[0].channel.group_name);
    }
    obj["flag"] = false;
    obj["openEntryModal"] = false;
    sessionStorage.setItem("botifier-workflow-bot-id" , chatbot.id);
    this.botService.showOtherTabs(obj);
    this.router.navigate(['workflow', chatbot.id]);
  }

  clearStartErrorDiv(){
    this.startInvalidError = false;
    this.startDateError = false;
    this.todayStartDateError = false; 
    this.updateStartInvalidError  = false;  
    this.updateStartDateError  = false;
    this.updateTodayStartDateError  = false;
 
  }

  clearEndErrorDiv(){
    this.endInvalidError = false;
    this.todayEndDateError = false;
    this.updateEndInvalidError  = false;
    this.updateTodayEndDateError = false;
  }

  openModal() {
    this.clearStartErrorDiv();
    this.clearEndErrorDiv(); 
    this.startDatemin = this.setTodayDateWithZeroSec(new Date());
    console.log(this.startDatemin);
    this.open_it = true;
  }

  closeModal() {
    this.open_it = false;
  }
 

  openEditModal(chatbotid) {
    this.clearStartErrorDiv();
    this.clearEndErrorDiv();
    this.botToEdit(chatbotid);
    this.open_edit = true;
    this.submitted1 = false;
  //  this.chatbotForm.get('enable_customer_handle_regex').setValidators([])

  }

  closeEditModal() {
    this.open_edit = false;
  }

  botToEdit(id){ 
    this.chatbotservice.botToEdit(id , this.organizationId)  
    .subscribe(response => { 
      this.edit_info = response.result; 
      this.chatbotEditForm.controls['name'].setValue(this.edit_info["name"]);
      this.chatbotEditForm.controls['organization_department_id'].setValue(this.edit_info["organization_department_id"]);
      // this.chatbotEditForm.controls['channel_type'].setValue(this.edit_info["channel_type"]); 
      this.chatbotEditForm.controls['organization_id'].setValue(this.organizationId); 
      this.chatbotEditForm.controls['multiple_allow'].setValue(this.edit_info["multiple_allow"]);
      this.chatbotEditForm.controls['enable_customer_handle_regex'].setValue(this.edit_info["enable_customer_handle_regex"]);
      this.chatbotEditForm.controls['test_mode_enabled'].setValue(this.edit_info["test_mode_enabled"]);
      if(this.edit_info["test_mode_enabled"])
      {
        this.chatbotEditForm.controls['enable_customer_handle_regex'].setValidators([Validators.required])
      }else{
        this.chatbotEditForm.controls['enable_customer_handle_regex'].clearValidators();
      }

      if(this.edit_info["start_at"] != undefined){
        this.chatbotEditForm.controls['start_at'].setValue(new Date(this.edit_info["start_at"]));
       // this.updateStartDateMin = new Date();
        this.selectStartDate("UPDATED");
      }else{
        this.chatbotEditForm.controls['start_at'].setValue("");
        //this.updateStartDateMin = new Date();
      }
      if(this.edit_info["end_at"] != undefined){ 
         this.chatbotEditForm.controls['end_at'].setValue(new Date(this.edit_info["end_at"]));
      }else{
        this.chatbotEditForm.controls['end_at'].setValue("");
      }
      this.chatbotEditForm.controls['id'].setValue(this.edit_info["id"]); 
    },

    error  => { });
  }

  onEditChatbot() {
    
    this.submitted1 = true;
    //this.chatbotEditForm.value.start_at = this.addTenSecInTime(this.chatbotEditForm.value.start_at);
    //this.chatbotEditForm.value.end_at = this.addTenSecInTime(this.chatbotEditForm.value.end_at);

    if(Date.parse(this.chatbotEditForm.value.start_at) > Date.parse(this.chatbotEditForm.value.end_at)){
        this.updateStartDateError = true;
        this.updateStartInvalidError = false;
        //this.toastr.error("Start date can not be greater than end date");
        return;
    }

    // stop here if form is invalid
    if (this.chatbotEditForm.invalid || this.updateStartInvalidError == true || 
        this.updateEndInvalidError == true || this.updateTodayStartDateError == true || 
        this.updateTodayEndDateError == true) {
          return;
    }
    this.isLoading = true;
    this.chatbotservice.updatechatBot(this.chatbotEditForm.value).subscribe(
      (response) => {
        if (response.statusCode=="200") {
          this.closeEditModal();
          this.chatbotlist();
          this.isLoading = false;
          this.toastr.success('Workflow has been updated successfully');
        }else if (response.statusCode== 400){
          this.toastr.error(response["message"]);
        }
      },
      (error: Response) => {
        this.isLoading = false;
      }
      ); 
  }
 
  confirm(chat_id): void {  
    this.isLoading = true;
    this.chatbotservice.delById(chat_id)
    .subscribe(result  => {
  
      this.isLoading = false;
      this.toastr.success('Workflow  deleted successfully');
      this.chatbotlist();
      this.modalRef.hide();
      },
      error  => {
       
        this.toastr.error('Something went wrong, please try again');
       });
  }

  charonly(event: any){
    const pattern =/^[a-zA-Z0-9_-]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }

removeSpace(e: any , type:any){
      // let clipboardData = e.clipboardData;
      // let val = clipboardData.getData('text').replace(/ /g,'');
      let val = e.target.value;
      val = val.replace(/ /g,'');
      if(type=='new'){
         this.chatbotForm.controls['name'].setValue(val);
         console.log(this.chatbotForm.value.name);
      }else if(type=='edit'){
         this.chatbotEditForm.controls['name'].setValue(val); 
      } 
 }
 
  decline(): void { 
    this.modalRef.hide();
  }

}

