import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ChatbotListComponent } from './chatbot-list/chatbot-list.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {DefaultUrlSerializer, UrlSegment, UrlSerializer, UrlTree} from '@angular/router';
//import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { library as fontLibrary } from '@fortawesome/fontawesome-svg-core';
// import { faCalendar,  faClock } from '@fortawesome/free-regular-svg-icons';
// import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from  '@angular/common/http';
import { AppComponent, RemoveUnderscorePipe,RemoveHypen } from './app.component';
import { NodeComponent } from './node/node.component';
import { NodeContainerComponent } from './node-container/node-container.component';
import { BotContainerComponent } from './bot-container/bot-container.component';
import { NodeService } from './node.serive';
import { DemoMaterialModule } from './material.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogConfig } from './dialog/dialog-config';
import { DialogRef } from './dialog/dialogRef';
import {ModalComponent } from './modal/modal.component';
import { ModalService } from './services/modal.service';
import { DialogModule } from './dailog.module';
 import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
 import { ToastrModule } from 'ngx-toastr';
 import { TabsModule } from 'ngx-bootstrap/tabs';
 import { AuthenticationService } from './services/authentication.service';
import { JwtInterceptor } from './helper/jwt.interceptor';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { CookieService } from 'ngx-cookie-service';
import { ContextMenuModule } from 'ngx-contextmenu';

export function get_token(authService: AuthenticationService) {
  return () => authService.getToken();
}

@NgModule({
  declarations: [
    AppComponent,
    NodeComponent,
    NodeContainerComponent,
    BotContainerComponent,
    ModalComponent,
    ChatbotListComponent,
    // DateTimePickerComponent
    RemoveUnderscorePipe,
    RemoveHypen,
    PagenotfoundComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,DemoMaterialModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    HttpClientModule,
    DialogModule,
    ContextMenuModule.forRoot(),
     NgbModule,
     ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton:true
    }),
     TabsModule.forRoot()
    //  FontAwesomeModule,
  ],

  
  providers: [NodeService,ModalService,AuthenticationService, CookieService,
  {provide: APP_INITIALIZER,useFactory: get_token, deps: [AuthenticationService], multi: true},
  {provide:HTTP_INTERCEPTORS,useClass:JwtInterceptor,multi: true}, 
  {provide: MatDialogRef, useValue: {}},{ provide: MAT_DIALOG_DATA, useValue: {} },
  {provide: DialogConfig, useValue: {}} , {provide: DialogRef, useValue: {}},
  ],
  
  bootstrap: [AppComponent],
  entryComponents:[NodeComponent,ModalComponent]
})
export class AppModule { }