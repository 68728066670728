
import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, Type, EmbeddedViewRef, ComponentRef } from '@angular/core';
import { DialogModule } from '../dailog.module';
import { DailogComponent } from '../dialog/dailog.component';
import { DialogConfig } from '../dialog/dialog-config';
import { DialogRef } from '../dialog/dialogRef';
import { DialogInjector } from '../dialog/dialog-injector';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import {GET_ALL_WORKFLOW,GET_DEPARTMENT} from '../constants/constantUrl'
//import {ORGANISATION_ID} from '../constants/constantVar'
import { ActivatedRoute } from '@angular/router';



@Injectable({
  providedIn: DialogModule
})
export class ModalService {
  dialogComponentRef: ComponentRef<DailogComponent>;
  organizationId : any;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
     private appRef: ApplicationRef, private injector: Injector,private http: HttpClient,
     private activatedRoute : ActivatedRoute) {
    // this.organizationId =  sessionStorage.getItem("botifier-workflow-org-id");
    // console.log("inside modal service ........")
  }
  private httpOptions = {
    headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
    })
};

  public open(componentType: Type<any>, config: DialogConfig) {
    const dialogRef = this.appendDialogComponentToBody(config);

    this.dialogComponentRef.instance.childComponentType = componentType;

    return dialogRef;
  }

  private appendDialogComponentToBody(config: DialogConfig) {
    const map = new WeakMap();
    map.set(DialogConfig, config);

    const dialogRef = new DialogRef();
    map.set(DialogRef, dialogRef);

    const sub = dialogRef.afterClosed.subscribe(() => {
      this.removeDialogComponentFromBody();
      sub.unsubscribe();
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DailogComponent);
    const componentRef = componentFactory.create(new DialogInjector(this.injector, map));

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    this.dialogComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody();
    });

    return dialogRef;
  }

  public removeDialogComponentFromBody() {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
  getAllWorkflow(org):Observable<any> 
  {
    return this.http.get(GET_ALL_WORKFLOW.toString()+"?organisationId="+org,this.httpOptions);
  }
  getDepartment(botId:any, org):Observable<any>
  {
    return this.http.get(GET_DEPARTMENT.toString()+"?botId="+botId + "&organisationId="+org,this.httpOptions);
  }
}
