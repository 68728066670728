import { Component, OnInit,Inject, OnChanges,OnDestroy, Input, ChangeDetectionStrategy,ComponentFactoryResolver, ViewContainerRef, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NodeService } from '../node.serive';
import { ActivatedRoute } from '@angular/router';
import { BotdataService } from '../services/botdata.service';
import { CommonService } from '../services/common.service';
import { NodesService } from '../services/nodes/nodes.service';
import { forEach } from '@angular/router/src/utils/collection';
import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { DialogRef } from '../dialog/dialogRef';
import { ModalService } from '../services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { ChatbotListService } from '../services/chatbotlist/chatbot-list.service';
import {checkBranching} from '../utils/checkBranching';
import {createNewBranch} from '../utils/createNewBranch';
import { endpointManage } from '../utils/endpointManage';
import { NodeData } from '../beans/nodeData';
import { links } from '../beans/links';
import { nextNode } from '../beans/nextNode';
import { NodeComponent, Node } from '../node/node.component';

// import example from '../services/apps.json';

@Component({
  selector: 'node-container',
  templateUrl: './node-container.component.html',
  styleUrls: ['./node-container.component.less'],
 // changeDetection: ChangeDetectionStrategy.OnPush
})
export class NodeContainerComponent implements OnInit ,OnDestroy {
  
  @Input() nodes = [];
  botdata: any;
  subscription: Subscription;
  bot_id:any=0;
  res:any;
  nodeDataModal:{};
  nodeFinalData;
  updateNode:{};
  targetarr: any = [];
  result:any;
  index:any=0;
  branchCount:any;
  optionBranching:any;
  nodeUpdate:boolean=false;
  refreshAfterFlag:boolean=false;
  chatObj:any={};
  orgId:any;
  getUpdate:boolean=false;
  branchingKeyObj:any={};
  updateAddNode:boolean=false;
  removeNode:boolean=true;
  BranchArrayConnection=[];
  branchingForChildLabel=[];
updateNodeEmpty:boolean=false;
removeNodeFlag:boolean=true;
branchArray=[];
branchNodeIdMap=new Map();
branchClickNodeIdMap=new Map();
branchingKey:any;
isUpdateMethodRun:boolean=true;
isBranchingEnable:boolean=false;
IsDeleteProcessCompleted:boolean=false;
isNodeCreated:boolean=true;
positionTop:any;
isSimmpleUpdate:boolean=false;
positionLeft:any;
getAllNodeMethodRun:boolean=true;
updatedUserInputNodeResponse:any;
chnageFromDummyNode:any;
isUpdateRun:boolean=true;
clickableNode:boolean=false;
hideGetAllMethod:boolean=false;
getAllNodesCall:boolean=true;
nodeDeleteRun:boolean=false;


nodeIdArray = [];
  @Input() connections = [];
  @Input() jsPlumbInstance;

  @ViewChild('nodes', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  data:any;
  nodeData:any;
  @Output() setItemEvent  = new EventEmitter();
  message: any = {};
  isLoading:boolean=false;
  @Output() entryNodeCreated = new EventEmitter<boolean>();

  

  constructor(private nodeService: NodeService,public NodesService: NodesService,private activeRoute:ActivatedRoute,private botDataService: BotdataService,private commonService:CommonService
    ,public nodeServices:NodesService, private dialog: DialogRef,  private modalService : ModalService ,
     private toastr: ToastrService ,  private chatbotservice:ChatbotListService,private r: ComponentFactoryResolver
    ) {
      this.bot_id = sessionStorage.getItem("botifier-workflow-bot-id");
      this.orgId = sessionStorage.getItem("botifier-workflow-org-id");
  }

  ngOnInit() {
   
    //this.bot_id = this.activeRoute.snapshot.params["id"];
     sessionStorage.removeItem('post_top');
    console.log(this.bot_id);
     this.subscription = this.commonService.updateDataAfter.subscribe((res) => {
       if(res["node_id"] != undefined ){
        console.log("Data successfully lodded:" );
        this.bot_id = sessionStorage.getItem("botifier-workflow-bot-id");
        this.nodeService.refreshInstance(this.viewContainerRef)
        this.getAllNodesCall=true;
        this.getAllNodes(this.bot_id,this.orgId,"");
       }
       
     });
      
    this.subscription = this.NodesService.getDeleteNodeId.subscribe((res) => {
      
      if(res != undefined  && res != 0){
      this.bot_id = sessionStorage.getItem("botifier-workflow-bot-id");
      this.orgId = sessionStorage.getItem("botifier-workflow-org-id");
      let nodeDeleteId=res
      res=0;
      this.isUpdateRun=false;
      let nodeDeleteStatus= this.nodeService.removeNode(nodeDeleteId,this.viewContainerRef);
        if(nodeDeleteStatus==true && this.bot_id){
           res=0;
           nodeDeleteId=null
           this.getAllNodesCall=true;
           this.nodeDeleteRun=true;
            this.getAllNodes(this.bot_id,this.orgId,"");
        }
      }
    })
    //this.bot_id = JSON.parse(sessionStorage.getItem("botId"));
    if(this.bot_id){
      this.getChatBotById(this.bot_id , this.orgId);
    }
  }  

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
}

  getChatBotById(id , orgId){
    this.isLoading=true;
    this.chatbotservice.botToEdit(id , orgId).subscribe((resp)=>{   
      this.chatObj = resp["result"];
      if(this.nodeDeleteRun==false){
       this.getAllNodesCall=true;
      }
      this.getAllNodes(id, orgId , "");
    })
  }

  getAllNodes(id,orgId , delteId){
      this.isNodeCreated=true;
      if(this.getAllNodesCall){
         this.getAllNodesCall=false;
    this.nodeServices.getAllNodes(id , orgId).subscribe(
      (response)=>{
        if(response.statusCode="200"){
          this.getAllNodesCall=false;
        console.log(id);
          this.isLoading=false;
          this.result=response.result;
          this.result.flag=false;
          this.refreshAfterFlag=true;
          
          this.botDataService.dataToTransmit=this.result;
          this.result.refreshFlag=this.refreshAfterFlag;
          this.getUser();
          if(this.removeNode && !this.updateAddNode ){
            this.getUpdateData()
          }
         
          this.data=this.result;
          this.chatObj['noOfNodes'] = this.result.length;

    if(this.data != undefined){
      this.nodes=this.data
    }
    this.nodeService.setContainerReset(this.viewContainerRef,delteId);

if(this.isNodeCreated){
  this.connections=[];
   this.isUpdateMethodRun=true;
  this.isNodeCreated=false;
  this.BranchArrayConnection=[];
    this.nodes.forEach((node,index) => {
      if(index==(this.nodes.length)-1){
       
        this.isNodeCreated=false;
        this.chnageFromDummyNode=false;
        this.positionTop="";
        this.positionTop=node.node_data.pos_top
        this.positionLeft=node.node_data.pos_left
        sessionStorage.setItem('post_top', this.positionTop);
         sessionStorage.setItem('post_left',  this.positionLeft);
         sessionStorage.setItem('last_node_id', node.node_id);
         sessionStorage.setItem('node_number', node.id);
      }
      this.nodeService.addDynamicNode(node);
      this.connections.push(node.node_data.links); 
      if(node.node_data.next_node.branch_nodes_id != null){
      // this.branchingForChildLabel  =Object.entries(node.node_data.next_node.branch_nodes_id).map(([key, value]) => ({key,value}));
       // Object.assign(this.BranchArrayConnection,Object.entries(node.node_data.next_node.branch_nodes_id).map(([key, value]) => ({key,value})));
      this.branchingForChildLabel=Object.entries(node.node_data.next_node.branch_nodes_id).map(([key, value]) => ({key,value}));
       this.branchingForChildLabel.forEach(element => {
      this.BranchArrayConnection.push(element);
    });
      }
      
     
     
      //this.connections.push(node.node_data.next_node.branch_nodes_id)
      
      if(node["node_type"] == "ENTRY"){
        this.chatObj["flag"] = true;
        this.chatObj["openEntryModal"] = false;     
      }  
    });

    
    this.botDataService.showOtherTabs(this.chatObj);

          setTimeout(() => {
            this.connections.forEach(connection => {
              if(connection != null && connection != undefined && connection != ""){
                  this.nodeService.addConnection(connection, this.BranchArrayConnection);
                  this.botDataService.dataToTransmit['refreshFlag']=false;    
              } 
            });
          },100);
          
}
 
        }
      }

    )
  }
  }

  getUser() {
    this.commonService.currentUser.subscribe(user => {
      this.nodeDataModal = user
      if(user['node_name'] && user['flag'] == true){
        if(user["clickNode"] != null && user["clickNode"] != undefined){
          this.updateNode=user["clickNode"];
          this.clickableNode=true;
          this.hideGetAllMethod=true;
        }else{
          this.clickableNode=false;
           this.hideGetAllMethod=true;
        }
        if(user['node_type']=="ENTRY"){
          this.hideGetAllMethod=true;
        }
        this.addNode(user['node_type'] , user['flag'],this.index);
        user['flag'] = false;
      } 
    }, err => {
    });
  }

  getUpdateData(){
    this.commonService.updateDataBranching.subscribe(updateData=>{
      if(updateData && this.isUpdateMethodRun){
        this.isUpdateMethodRun=false;
        this.isUpdateRun=true;
        this.removeNode=true;
        this.chnageFromDummyNode=updateData["chnageFromDummy"];
          this.getUpdateBranchingNode(updateData)
      }
       
    }, err => {
    });
  }
getUpdateBranchingNode(updateNodeJson){
 
  
  if(updateNodeJson.node_sub_type=="USER_INPUT" && (updateNodeJson.node_data.inbound.input.type=="Dropdown" ||  updateNodeJson.node_data.inbound.input.type=="Buttons") && updateNodeJson.node_data.inbound.input.branching &&  updateNodeJson.node_data.inbound.input.mutli_select==false &&
   updateNodeJson.node_data.inbound.input.selection_option == "MANUAL")
      {
          this.isBranchingEnable=true;
          let resultsOption;
          let branchingNodeJson;
          let arrayOfBranching;
          let resultsRemove=[];
          let arrayCount;
        

           let newOption=updateNodeJson.node_data.inbound.input.options;
           newOption=checkBranching.getUniqueListBy(newOption,'value');
           if(updateNodeJson.node_data.next_node.branch_nodes_id != null){
            branchingNodeJson=updateNodeJson.node_data.next_node.branch_nodes_id;
            arrayOfBranching=Object.entries(branchingNodeJson).map(([key, value]) => ({key,value}));
            resultsOption = newOption.filter(({ value: id1 }) => ! arrayOfBranching.some(({ key: id2 }) => id2 === id1));
           resultsRemove = arrayOfBranching.filter(({ key: id1 }) => ! newOption.some(({ value: id2 }) => id2 === id1));
            
           arrayCount=arrayOfBranching.length;
           }else{
             resultsOption=newOption;
             arrayOfBranching=0;
             arrayCount=0;
             resultsRemove=[];
           }
            let count=[];
            if(resultsOption.length > 0){
              resultsOption.forEach(element => {
                  arrayCount++;
                  count.push(arrayCount);
              });
            }
            if(resultsRemove.length > 0 && resultsOption.length == 0 ){
              this.isUpdateRun=true;
                resultsRemove.forEach(element => {
                  this.removeNode=true;
                  let yNode=element.value+"_top";
                   this.nodeServices.removeByNodeId(element.value,this.bot_id, element.value).toPromise().then(response => {
                       let nodeDeleteId:string=response
                       console.log(response);
                       this.nodeService.removeNode(element.value,this.viewContainerRef);
                     });
                      arrayOfBranching.forEach(e => {
                        if(e.key== element.key){
                          delete branchingNodeJson[e.key];
                        }
                     });
                     updateNodeJson.node_data.next_node.branch_nodes_id=branchingNodeJson;
                    
                     updateNodeJson.node_data.links.forEach(arrayConnection => {
                       if(arrayConnection.y[0]==yNode){
                       updateNodeJson.node_data.links = updateNodeJson.node_data.links.filter(item => item.y[0] != yNode);
                       }
                     });
                     let newLinkPostion=endpointManage.changeEndpoint(updateNodeJson.node_data.next_node.branch_nodes_id,updateNodeJson.node_id);
                     updateNodeJson.node_data.links=newLinkPostion;
                     
                     this.updateNode=updateNodeJson;
                    
               });
                this.nodeUpdateJosn(updateNodeJson,"");
            
             }else{
                this.IsDeleteProcessCompleted=true;
             }


              if(resultsOption.length > 0 && this.IsDeleteProcessCompleted && resultsRemove.length == 0){
                this.isUpdateRun=true;
                resultsOption.forEach((element,index) => {
                  console.log(index);
                  
                 // let addNodeCount= count[index]-resultsRemove.length;
                   let optValueIndex = updateNodeJson.node_data.inbound.input.options.findIndex(x => x.value === element.value);
                  this.updateAddNode=true;
                  this.removeNode=false;
                  this.updateNode=updateNodeJson;
                  this.branchArray.push();
                  this.branchingKey=resultsOption
                  this.branchCount=resultsOption.length;
                  this.createBranchNode(updateNodeJson,index,optValueIndex)
                });
             }
             if(resultsOption.length > 0 && resultsRemove.length > 0){
                 this.isUpdateRun=true;
                  resultsRemove.forEach(element => {
                  this.removeNode=true;
                  let yNode=element.value+"_top";
                   this.nodeServices.removeByNodeId(element.value,this.bot_id, element.value).toPromise().then(response => {
                       let nodeDeleteId:string=response
                       console.log(response);
                       this.nodeService.removeNode(element.value,this.viewContainerRef);
                     });
                       arrayOfBranching.forEach(e => {
                        if(e.key== element.key){
                          delete branchingNodeJson[e.key];
                        }
                     });
                     updateNodeJson.node_data.next_node.branch_nodes_id=branchingNodeJson;
                    
                     updateNodeJson.node_data.links.forEach(arrayConnection => {
                       if(arrayConnection.y[0]==yNode){
                       updateNodeJson.node_data.links = updateNodeJson.node_data.links.filter(item => item.y[0] != yNode);
                       }
                     });
                     let newLinkPostion=endpointManage.changeEndpoint(updateNodeJson.node_data.next_node.branch_nodes_id,updateNodeJson.node_id);
                     updateNodeJson.node_data.links=newLinkPostion;
                    
               });
               resultsOption.forEach((element,index) => {
                  console.log(index);
                   let optValueIndex = updateNodeJson.node_data.inbound.input.options.findIndex(x => x.value === element.value);
                  this.updateAddNode=true;
                  this.updateNode=updateNodeJson;
                  this.branchArray.push();
                  this.branchingKey=resultsOption
                  this.branchCount=resultsOption.length;
                  this.createBranchNode(updateNodeJson,index,optValueIndex)
                });
                
             }
             if(resultsOption.length == 0 && resultsRemove.length == 0){
              this.isSimmpleUpdate=true;
             let updateLinkForCheckBranchingCheck=[];
              updateNodeJson.node_data.links.forEach(element => {
               updateLinkForCheckBranchingCheck.push(element.y[0]);
            });
              let extraLinks =  updateLinkForCheckBranchingCheck.filter(item => ! arrayOfBranching.some(({ value: id2 }) => id2+"_top" === item));
              if(extraLinks.length != 0 && extraLinks != undefined){
              extraLinks.forEach(element => {
                  let nodeIdDelete = element.split("_")[0];
                  console.log(nodeIdDelete);
                  
                 updateNodeJson.node_data.links = updateNodeJson.node_data.links.filter(item => item.y[0] != element);
                 this.nodeServices.removeByNodeId(nodeIdDelete,this.bot_id,nodeIdDelete).toPromise().then(response => {
                       let nodeDeleteId:string=response
                       console.log(response);
                       
                     });
              });
               updateNodeJson.node_data.links=endpointManage.changeEndpoint(updateNodeJson.node_data.next_node.branch_nodes_id,updateNodeJson.node_id);
              }
              this.updateNode=updateNodeJson;
              this.nodeUpdateJosn(updateNodeJson,"");
             }            
          } 
          else{
              this.isSimmpleUpdate=true;
                  this.updateNode=updateNodeJson;
                this.nodeUpdateJosn(updateNodeJson,"");
          }
   
     
}
  





  createNode(type){
    this.nodeData=this.nodeDataModal;
    this.data=this.botDataService.dataToTransmit;
    return {
      bot_id:this.nodeData['bot_id'],
      node_id:uuid(),
      node_name:this.nodeData['node_name'],
      node_type: this.nodeData['node_type'],
      node_sub_type:this.nodeData['node_sub_type'],
      node_data:this.nodeData['node_data'],
      goal_name:this.nodeData['goal_name'],
      goal_value:this.nodeData['goal_value'],
      goal_unit:this.nodeData['goal_unit']
     
    }
  }

  createBranchNode(nodeJson,loopIndex,optValueIndex){
    this.nodeDataModal=createNewBranch.createNode(this.bot_id,nodeJson,loopIndex,optValueIndex);
    this.addNode(this.nodeDataModal['node_type'] , this.nodeDataModal['flag'],loopIndex+1);
  }


 
  addNode(type , flag,loopIndex) {
      let nodeJson = this.createNode(type);
      let nodes:any = this.botDataService.dataToTransmit;
      
     if(flag==true){
      
        this.nodeServices.Loading=true;
         if(nodeJson.node_sub_type =="DUMMY"){
            let nodeNumber=sessionStorage.getItem('node_number');
            if(this.updateNode['node_sub_type']=="USER_INPUT"){
            this.branchNodeIdMap.set(nodeJson.node_name,nodeJson.node_id);
            }
            nodeJson.node_name= this.updateNode['node_name']+"_"+nodeJson.node_name+"_"+(parseInt(nodeNumber)+loopIndex);
           
            
         }
          if(this.clickableNode){ 
          if(this.updateNode['node_sub_type']=="USER_INPUT" && this.updateNode['node_data']['inbound']['input']['branching']){
            let sourceInputoption=this.updateNode['node_data']['inbound']['input']['options'];
            let sourceBranchingMap=this.updateNode['node_data']['next_node']['branch_nodes_id'];
            for (let index = 0; index < sourceInputoption.length; index++) {
                let option=sourceInputoption[index];
                if(sourceBranchingMap[option.value] == null || sourceBranchingMap[option.value] == undefined ){
                      this.branchClickNodeIdMap.set(option.value,nodeJson.node_id);
                      sourceBranchingMap[option.value]=nodeJson.node_id;
                      let newClickNodeParantSetArray=[];
                      newClickNodeParantSetArray.push({"node_id":this.updateNode['node_id'],"node_type":this.updateNode['node_sub_type'],"branching_node_key":option.value})
                      nodeJson.node_data.parent_nodes_type=newClickNodeParantSetArray;
                      break;
                  }
                }
            this.branchNodeIdMap=sourceBranchingMap;
            this.branchingKeyObj=[... this.branchClickNodeIdMap].reduce((o, [key, value]) => (o[key] = value, o), {});
          } else{
               let newClickNodeParantSetArray=[];
                newClickNodeParantSetArray.push({"node_id":this.updateNode['node_id'],"node_type":this.updateNode['node_sub_type'],"branching_node_key":null})
                nodeJson.node_data.parent_nodes_type=newClickNodeParantSetArray;
          }
        }
       


        this.nodeServices.saveNode(nodeJson).toPromise().then(response => {
          if(response["statusCode"] == 200){ 
               nodeJson=response["result"];
              let newNodePosTopUpdate=response["result"]["node_data"]["pos_top"]
              let newNodeposLeftUpdate=response["result"]["node_data"]["pos_left"]
            sessionStorage.setItem('post_top',  newNodePosTopUpdate);
             sessionStorage.setItem('post_left',  newNodeposLeftUpdate);
              sessionStorage.setItem('last_node_id',  response["result"]["node_id"]);
              sessionStorage.setItem('node_number',  response["result"]["id"]);
            if(nodeJson.node_sub_type !="DUMMY" && (nodeJson.node_sub_type == "USER_INPUT" || nodeJson.node_sub_type == "IF_ELSE" )){
              if(this.clickableNode && ((nodeJson.node_sub_type == "USER_INPUT" && nodeJson.node_data.inbound.input.branching) || nodeJson.node_sub_type == "IF_ELSE")){
                 let linkArray=[];
                    linkArray.push(nodeJson.node_id+"_top");
                    if(nodeJson.node_sub_type == "IF_ELSE" && this.updateNode['node_sub_type']=="USER_INPUT"){
                        let newLinkPostion=endpointManage.changeEndpoint(this.branchNodeIdMap,this.updateNode['node_id']);
                        this.updateNode['node_data']['links']=newLinkPostion;
                    }else{
                      this.updateNode=ClickNodeUpdate.setLinkAndNextNode(linkArray,this.updateNode);
                    }
                    this.nodeServices.updateNodeData(this.updateNode, this.updateNode['id']).subscribe(res => {
                    if(res["statusCode"] == 200){
                      
                    }
                   });
                   this.clickableNode=false;
              }
              if(this.clickableNode==false && ((nodeJson.node_sub_type == "USER_INPUT"  && nodeJson.node_data.inbound.input.branching )|| nodeJson.node_sub_type == "IF_ELSE")){
                this.updateNode=response["result"];
              
             if(nodeJson.node_sub_type == "USER_INPUT"){
               this.isBranchingEnable=this.updateNode['node_data']['inbound']['input']['branching'];
              this.optionBranching=checkBranching.getBranchingNodes(nodeJson);
             
              
              if(this.optionBranching != null){
                  let loopIndex=0;
                  this.branchCount=this.optionBranching.length;
                for (this.index = 1; this.index <= this.branchCount; this.index++) {
                  let optValueIndex = nodeJson.node_data.inbound.input.options.findIndex(x => x.value ===this.optionBranching[this.index-1].value);
                  this.createBranchNode(nodeJson,loopIndex,optValueIndex);
                  loopIndex++;
                }
              }
             }
              }
            }
            if(nodeJson.node_sub_type=="DUMMY"){
                this.isUpdateRun=true;
                this.nodeIdArray.push(nodeJson.node_id);
                this.targetarr.push(nodeJson.node_id+"_top");
                if((this.targetarr.length) == this.branchCount){
                    this.removeNode=false;
                    this.getUpdate=true;
                      this.branchingKeyObj=[... this.branchNodeIdMap].reduce((o, [key, value]) => (o[key] = value, o), {});
                }
            }
            if(this.clickableNode){
                this.targetarr.push(nodeJson.node_id+"_top");
                this.isUpdateRun=true;
                if(this.updateNode['node_sub_type']=="USER_INPUT" && this.updateNode['node_data']['inbound']['input']['branching'] ){
                  this.isBranchingEnable=true;
                  this.updateAddNode=true;
                  
                  this.clickableNode=false;
                }else{
                    this.isBranchingEnable=false;
                }
                this.nodeUpdateJosn(this.updateNode,"")
            }
            if(nodeJson.node_sub_type == "IF_ELSE" ){
              this.updateNode=nodeJson;
              let optValueIndex=0;
              for (let index= 1; index <= 2;  index++) {
                  this.createBranchNode(nodeJson,index,optValueIndex);
                  this.branchCount=2;
                  this.isBranchingEnable=true;
              }
            }
          if(this.branchCount== this.targetarr.length){
             this.nodeUpdateJosn(this.updateNode,"");
             
          }
          this.nodeService.addDynamicNode(response['result']);
             if(this.hideGetAllMethod){
              this.nodeServices.getAllNodes(response['result']['bot_id'] , this.orgId).subscribe(
                  (resp)=>{
                        if(resp != undefined){
                            this.nodeServices.Loading=false;
                            this.modalService.removeDialogComponentFromBody();
                            this.toastr.success('Node has been created successfully'); 
                            this.botDataService.dataToTransmit = resp["result"];
                            nodes = this.botDataService.dataToTransmit;
                            nodes.forEach(node => {
                                if(node["node_type"] == "ENTRY"){
                                  this.chatObj["id"] = node["bot_id"];
                                  this.chatObj["name"] = node["bot_name"];
                                  this.chatObj["is_active"] = false;
                                  this.chatObj["flag"] = true;
                                  this.chatObj["openEntryModal"] = false;
                                  this.chatObj['noOfNodes'] = resp["result"].length;
                                  this.botDataService.showOtherTabs(this.chatObj);
                                }
                            });
                        }
                  })
             }
              this.nodeServices.Loading=false;
              this.modalService.removeDialogComponentFromBody();
          }
         
        }).catch(error => {
        });
  } 
    //this.nodeService.addDynamicNode(this.createNode(type));
}

nodeUpdateJosn(NodeJson,arrayRemove){
  if(this.isUpdateRun){
  let nodeData = new NodeData;
  let nodes:any = this.botDataService.dataToTransmit;
  let arrayLink=[];
  if(!this.updateAddNode && !this.removeNode && this.isBranchingEnable && !this.isSimmpleUpdate){
          let Links = new links;
          let NextNode = new nextNode;
          if(NodeJson.node_sub_type == "USER_INPUT"){  
          let position = 0.00;
          let increased_by = 1 / (this.branchNodeIdMap.size + 1);
          for (let [key, value] of this.branchNodeIdMap) {
           let element = value+"_top";
           let yArray:any=[];
           let Links = new links;
             position = position + increased_by;
            position = Math.round (position * 100) / 100;;
            Links.$x = this.updateNode['node_id']+"_bottom_"+ position;
            yArray.push(element)
            Links.$y = yArray;
            arrayLink.push(Links); 
          
            };
          }
           NextNode.$branch_nodes_id =this.branchingKeyObj
          if(NodeJson.node_sub_type == "IF_ELSE"){
            for (let index = 0; index < this.targetarr.length; index++) {
               NextNode.$branch_nodes_id=null;
             let Links = new links;
            let element = this.targetarr[index];
            let yArray:any=[];
              if(index == 0){
                 Links.$x = this.updateNode['node_id']+"_Left"
                 NextNode.$yes_node_id=element.split("_")[0];
              }else{
                Links.$x = this.updateNode['node_id']+"_Right"
                 NextNode.$no_node_id=element.split("_")[0];
              }
              yArray.push(element);
               Links.$y = yArray;
            arrayLink.push(Links);
          }
       }
          nodeData.$links=arrayLink;
          NextNode.$node_id = null;
         
          if(NodeJson.node_sub_type != "IF_ELSE"){
          NextNode.$yes_node_id= null;
          NextNode.$no_node_id=null;
          }
          nodeData.$next_node=NextNode;
          this.updateNode['node_data']['links']=nodeData.$links;
          this.updateNode['node_data']['next_node']=nodeData.$next_node;
  }else if(this.updateAddNode){
     if(NodeJson.node_data.next_node.branch_nodes_id != null){
       Object.assign(NodeJson.node_data.next_node.branch_nodes_id, this.branchingKeyObj);
    }else{
      NodeJson.node_data.next_node.branch_nodes_id=this.branchingKeyObj;
    }
      let arrayLink=[];
      this.removeNode=true;
     NodeJson.node_data.links.forEach(element => {
       if(element.x == "" || element.y[0] == null && element.y[0].length == 0){
          NodeJson.node_data.links=NodeJson.node_data.links.filter(item => item != element); 
       }
     });
     for (let index = 0; index < this.targetarr.length; index++) {
          let updateIndex=Object.keys(NodeJson.node_data.next_node.branch_nodes_id).length+(index+1);
           let element = this.targetarr[index];
           let yArray:any=[];
           let Links = new links;
            Links.$x = this.updateNode['node_id']+"_bottom_"+(updateIndex)/10;
            yArray.push(element)
            Links.$y = yArray;
            arrayLink.push(Links); 
          
        }
      NodeJson.node_data.links.push(...arrayLink);
 
   if(NodeJson.node_data.links.length != 0 && NodeJson.node_data.links[0].y[0] != null && NodeJson.node_data.links[0].y.length != 0){
       let newLinkPostion=endpointManage.changeEndpoint(NodeJson.node_data.next_node.branch_nodes_id,NodeJson.node_id);
        NodeJson.node_data.links=newLinkPostion;
     }else{
       NodeJson.node_data.links=[];
     }
   
   
    this.updateNode=NodeJson;
  }
  if(this.clickableNode){
    this.updateNode=ClickNodeUpdate.setLinkAndNextNode(this.targetarr,this.updateNode)
  }
  

        this.nodeServices.Loading=true;
        this.nodeServices.updateNodeData(this.updateNode, this.updateNode['id']).toPromise().then(response => {
          if(response["statusCode"] == 200){ 
            this.modalService.removeDialogComponentFromBody();
            this.targetarr=[];
            this.nodeIdArray=[];
            this.getUpdate=false;
            this.updateAddNode=false;
            this.branchArray=[];
             this.nodeServices.Loading=false;
            this.clickableNode=true;
             this.isUpdateRun=false;
            this.isUpdateMethodRun=true;
            this.branchNodeIdMap=new Map();
            this.branchClickNodeIdMap=new Map();
            this.branchingKeyObj={};
            if(!this.isSimmpleUpdate){ 
              (this.updateNode['bot_id'],this.orgId,"");
            }else{
              //this.isSimmpleUpdate=false;
            }
            // if(response["result"]["node_sub_type"] == "IF_ELSE"){
            //   this.nodeService.removeNodeDummy(response["result"]["node_id"]);
            //   this.nodeService.addDynamicNode(response['result']);
            //     this.nodeServices.Loading=false;
                
            // }
            // if(response["result"]["node_sub_type"] == "IF_ELSE"){
            //    if(this.updatedUserInputNodeResponse){
            //         setTimeout(() => {
            //        this.BranchArrayConnection=[];
            //         this.branchingForChildLabel=Object.entries( this.updatedUserInputNodeResponse['node_data']['next_node']['branch_nodes_id']).map(([key, value]) => ({key,value}));
            //               this.branchingForChildLabel.forEach(element => {
            //               this.BranchArrayConnection.push(element);
            //             });
                       
            //           //jsplumbConst.deleteConnection( this.updatedUserInputNodeResponse['node_data']['links']);
            //          this.nodeService.addConnection( this.updatedUserInputNodeResponse['node_data']['links'],  this.BranchArrayConnection);
            //      },50);
            //      }
            // }
                     
          }
           //setTimeout(() => {
           if((this.chnageFromDummyNode || this.removeNode ||this.clickableNode|| this.isSimmpleUpdate) && response["result"]["node_sub_type"]=="USER_INPUT" ||this.clickableNode){
               this.chnageFromDummyNode=false;
               this.removeNode=false;
               this.clickableNode=false;
               this.isSimmpleUpdate=false;
               this.hideGetAllMethod=false;
               this.viewContainerRef= this.nodeService.refreshInstance(this.viewContainerRef)
              //let factory = this.r.resolveComponentFactory(NodeComponent);
              this.getAllNodesCall=true;
              this.getAllNodes(this.updateNode['bot_id'],this.orgId,"");
             }
             //},70);
          if(response["result"]["node_sub_type"]=="USER_INPUT" && this.isBranchingEnable){
            this.updatedUserInputNodeResponse=response["result"];
           setTimeout(() => {
             this.BranchArrayConnection=[];
             if(response["result"]['node_data']['next_node']['branch_nodes_id'] != null ){
             this.branchingForChildLabel=Object.entries(response["result"]['node_data']['next_node']['branch_nodes_id']).map(([key, value]) => ({key,value}));
                  this.branchingForChildLabel.forEach(element => {
                  this.BranchArrayConnection.push(element);
                });
             }
            
            let uniqueCount=checkBranching.getUniqueListBy(response["result"].node_data.inbound.input.options,'value');
            // this.nodeService.updateRef(response["result"], uniqueCount.length);
          this.nodeService.addConnection(arrayLink,  this.BranchArrayConnection);
           this.botDataService.dataToTransmit['refreshFlag']=false; 
          // this.getAllNodes (this.updateNode['bot_id'],this.orgId,"");
           },50);
             this.nodeServices.Loading=false;

            


          }
         
          })
        

             
}else{
   this.modalService.removeDialogComponentFromBody();
    this.targetarr=[];
            this.nodeIdArray=[];
            this.getUpdate=false;
            this.updateAddNode=false;
            this.branchArray=[];
             this.isUpdateRun=false;
            this.isUpdateMethodRun=true;
            this.branchNodeIdMap=new Map();
}
}


}
export interface DialogData {
  animal: string;
  
}
export class ClickNodeUpdate{

static setLinkAndNextNode(targetarr,updateNode){
    let arrayLink=[];
    let nodeData = new NodeData;
    let Links = new links;
    Links.$y=targetarr;
    Links.$x = updateNode['node_id']+"_bottom";
    arrayLink.push(Links);
    nodeData.$links=arrayLink;
    updateNode['node_data']['links']=nodeData.$links;
    targetarr.forEach(element => {
        let nextNodeId = element.split("_")[0];
    updateNode['node_data']['next_node']['node_id']=nextNodeId;
    });
  return updateNode;
}

}

