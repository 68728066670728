import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
   public currentUser: boolean =false;
   public currentUserToken: any;

   constructor(
       private router: Router,
       private authenticationService: AuthenticationService
   ) { }
   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       this.authenticationService.session.subscribe(session=> this.currentUser = session)
      // this.authenticationService.currentUser.subscribe(user=> this.currentUserToken = user)
       if (this.currentUser) {
         return true;
       }
       return false;
   }
}