import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import { RouteReuseStrategy, Router } from '@angular/router';
import { GET_TOKEN } from '../constants/constantUrl';
import { Token } from '@angular/compiler';
// import 'rxjs/add/operator/toPromise';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
   private currentUserSubject = new BehaviorSubject(<any>"");
   public currentUser = this.currentUserSubject.asObservable();
   private currentSession=  new BehaviorSubject<boolean>(false);
   session = this.currentSession.asObservable();


   constructor(private http: HttpClient) {
       //this.currentUserSubject.next(JSON.parse(localStorage.getItem('token')));
      // this.isSessionexists();
   }



   public get currentUserValue(): Token {
       return this.currentUserSubject.value;
   }


   public isSessionexists() {
      var token = sessionStorage.getItem('token');
      const userSession =(token != null && token !== undefined) ? true : false;
      this.currentSession.next(userSession);
   }

   getToken(): Promise<any> {
    const promise= this.http.get(GET_TOKEN.toString()).toPromise()
    .then(settings => {
     
              // login successful if there's a jwt token in the response
              //  if (settings.token) {
              //      // store user details and jwt token in local storage to keep user logged in between page refreshes
               sessionStorage.setItem('token', JSON.stringify(settings["token"]));
               this.currentUserSubject.next(settings["token"]);
               this.isSessionexists();
              //  }
              //return settings;
           }).catch(e=>{
              
           });
           return promise;
          }
   }

  
   

