import { NodeJson } from '../beans/nodeJson'
import { Data } from '../beans/Data';
import { NodeData } from '../beans/nodeData';
import { input } from '../beans/input';
import { actions } from '../beans/actions';
import { links } from '../beans/links';
import { link } from '../beans/link';
import { context_variables } from '../beans/context_variables';
import { nextNode } from '../beans/nextNode';


export class createNewBranch{



    static createNode(bot_id,parantNode,loopIndex,optValueIndex){
     let positionTop= sessionStorage.getItem('post_top');
     let positionLeft= sessionStorage.getItem('post_left');
    

      let parantNodeJson=parantNode;
      let dummyNodeName;
   if(parantNodeJson.node_sub_type =="USER_INPUT"){
          dummyNodeName=parantNodeJson.node_data.inbound.input.options[optValueIndex].value;
      }else{
         dummyNodeName="Dummy"
      }
    
    let arrayLink=[];
    let nodeJson = new NodeJson;
      let nodeData = new NodeData;
      let NextNode = new nextNode;
      let Links = new links;
      let user_attr_select:any='';
      let contextVariables = new context_variables;
     
      nodeJson.$bot_id=parantNode["bot_id"];
     // nodeJson.$node_name =  "Dummy_"+parantNode['node_name']+"_"+dummyNodeName+"_"+index;
     
      nodeJson.$node_name=dummyNodeName;
      nodeJson.$node_type = "ACTION";
      nodeJson.$node_sub_type = "DUMMY";
      contextVariables.$global = false;
      contextVariables.$values =[];
      contextVariables.$user_attribute = false;
      contextVariables.$user_attribute_key =user_attr_select;
      nodeData.$context_variables = contextVariables;
      
      Links.$x = ""
      Links.$y = [];
      
      arrayLink.push(Links)
      nodeData.$links= arrayLink;
      NextNode.$node_id = "";
      NextNode.$yes_node_id = null;
      NextNode.$no_node_id = null;
      nodeData.$next_node = NextNode;
      nodeData.$pos_top =  parseInt(positionTop)+150;
      nodeData.$pos_left = createNewBranch.createleft(positionLeft,loopIndex);;
      
      let newArray: any = [];
      newArray.push({"node_id":parantNode['node_id'],"node_type":parantNode['node_sub_type'],"branching_node_key":dummyNodeName});
      console.log(newArray);
     let newData;
    //   if (parantType) {
    //     for (let [key, value] of parantType) {
    //       newData.push([key] = value);
    //     }
    //   }
      nodeData.$parent_nodes_type=newArray;
      nodeJson.$node_data = nodeData;
      nodeJson.$flag = true;
      
      return nodeJson;

    }
    
    static createTop(parantNode){
      if(parantNode){
        return parantNode.node_data.pos_top + 150;
      }

    }
    
    static createleft(positionLeft,loopIndex){
      if(positionLeft){
       
        //return (-116) +(index * 200);
       // return parantNode.node_data.pos_left - 200;
      
         return (parseInt(positionLeft)+100) +(loopIndex * 200);
     
       
        }
      

    }







}