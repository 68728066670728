import { Injectable } from '@angular/core';
import { SAVE_NODE, DEL_NODE, GET_USER_PROPERTY, GET_NODE } from 'src/app/constants/constantUrl';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import {GET_ALL_NODE , UPDATE_NODE,GET_TEMPLATE,GET_CAMPAIGNS,GET_APIS,GET_WEBHOOK,GET_CONTEXT_DETAILS,GET_DATA_OPTIONS} from '../../constants/constantUrl'
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ContentResponse } from 'src/app/beans/ContentResponse';
import { environment } from '../../../environments/environment'
import { map, catchError } from 'rxjs/operators';
import {ORGANISATION_ID} from 'src/app/constants/constantVar'
import { ActivatedRoute } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NodesService {
  baseUrl = environment.baseUrl;
  private subject = new Subject<any>();
  customObservable = this.subject.asObservable();
  
  private deleteNodeId=new BehaviorSubject<any>(0);
  getDeleteNodeId=this.deleteNodeId.asObservable();
  
  
  organizationId:any;
  Loading:boolean=false;
  
  constructor(private http: HttpClient , private activatedRoute : ActivatedRoute) {
    // this.organizationId = sessionStorage.getItem("botifier-workflow-org-id");
    // console.log("inside nodes service .... ")
  }
  
  private httpOptions = {
    headers: new HttpHeaders({
      "Accept": "application/json",
      "Content-Type": "application/json"
    })
  };
  
  saveNode(json){
    
    return this.http.post<ContentResponse>(SAVE_NODE.toString(),json,this.httpOptions);
  }
  getAllNodes(id:any , orgId:any): Observable<any> {
    return this.http.get(GET_ALL_NODE.toString()+id+"?organisationId="+orgId);
  }
  
  getNode(id:any){
    return this.http.get<ContentResponse>(GET_NODE.toString()+id);
  }
  delById(id:any,node_id): Observable<any> {
    return this.http.delete(DEL_NODE.toString()+id).pipe(map((res:Response) =>  this.subject.next(res['id']=node_id)))
    
  }
  removeByNodeId(id:any,botId:any,node_id):Observable<any> {
    //return this.http.delete(DEL_NODE.toString()+id+"/bot/"+botId).pipe(map((res:Response) =>  this.subject.next(res['id']=node_id)))
    return this.http.delete(DEL_NODE.toString()+id+"/bot/"+botId).pipe(map((res:Response) =>  (res['id']=node_id)))
  }
  
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  
   updateNodeData(json , id:any){
    return  this.http.put<ContentResponse>(UPDATE_NODE.toString()+id,json,this.httpOptions);
  }
  getTemplate(botId:any , org):Observable<any> 
  {
    return this.http.get(GET_TEMPLATE.toString()+"?botId="+botId+ "&organisationId="+org,this.httpOptions);
  }
  getCampaign(botId:any , org):Observable<any> 
  {
    return this.http.get(GET_CAMPAIGNS.toString()+"?botId="+botId+ "&organisationId="+org,this.httpOptions);
    
  }
  getApis(org):Observable<any> 
  {
    return this.http.get(GET_APIS.toString()+"?organisationId="+org,this.httpOptions);
  }
  
  getUserProperty(botId:any):Observable<any>{
    return this.http.get(GET_USER_PROPERTY.toString()+"?id="+botId , this.httpOptions);
  }
  
  getwebhook(org):Observable<any> 
  {
    return this.http.get(GET_WEBHOOK.toString()+"?organisationId="+org,this.httpOptions);
  }
  
  setNodeDeleteId(data:any){
    this.deleteNodeId.next(data)
  }
  
  getContextVariable(botId:any,org):Observable<any>{
    return this.http.get(GET_CONTEXT_DETAILS.toString()+botId+ "?organisationId="+org,this.httpOptions);
  }
  getDataOptions(nodeId:any,org):Observable<any>{
    return this.http.get(GET_DATA_OPTIONS.toString()+nodeId+ "?organisationId="+org,this.httpOptions);
    
  }
  
}
