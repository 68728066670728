import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'workflow-builder';
  constructor(private  authService: AuthenticationService){

    // this.authService.getToken().then((response) => {

    // });
  }
  
}

@Pipe({ name: 'removeUnderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/_/g, " ");
  }
}

@Pipe({ name: 'removehypen' })
export class RemoveHypen implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/-/g, " ");
  }
}