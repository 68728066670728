export class nextNodeUpdate{

    static updateInput(nodeUpdateData: any, nextNode: string,anctorType:any) {
        if (nodeUpdateData.node_type == "ENTRY") {
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if (nodeUpdateData.node_sub_type == "OUTBOUND") {
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if (nodeUpdateData.node_sub_type == "API" || nodeUpdateData.node_sub_type == "WEBHOOK") {
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if(nodeUpdateData.node_sub_type =="USER_INPUT"){
        nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;     
        }
        if(nodeUpdateData.node_sub_type =="WEBHOOK"){
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if(nodeUpdateData.node_sub_type =="WORKFLOW_TRIGGER"){
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if(nodeUpdateData.node_sub_type =="STOP"){
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if(nodeUpdateData.node_sub_type =="DEPARTMENT_SWITCHER"){
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if(nodeUpdateData.node_sub_type =="DELAY"){
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if(nodeUpdateData.node_sub_type =="WAIT_UNTIL"){
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if(nodeUpdateData.node_sub_type == "LOOP"){
            nodeUpdateData.node_data.next_node.node_id= nextNode
            return nodeUpdateData;
        }
        if(nodeUpdateData.node_sub_type =="IF_ELSE"){
            if(anctorType=="Left"){
                 nodeUpdateData.node_data.next_node.yes_node_id = nextNode
            }else if(anctorType=="Right"  ){
                 nodeUpdateData.node_data.next_node.no_node_id = nextNode
            }
            nodeUpdateData.node_data.next_node.node_id = null;
            return nodeUpdateData;
        }else{
            return nodeUpdateData;
        }

        

    } 

}