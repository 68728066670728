import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment'
import {GET_ALL_NODE,GET_WORKFLOW,GET_CHAT_BOT,DEL_CHATBOT_BY_ID,PAUSE_WORKFLOW,RESUME_WORKFLOW,BOT_EDIT,UPDATE_WORKFLOW,GET_CHATBOT_LIST_USER,GET_COMMUNICATION_CHANNEL, VALIDATE_TOKEN, GET_DEPARTMENTS_NEW} from '../../constants/constantUrl'
import { Content } from 'src/app/beans/content';
import { ContentResponse } from 'src/app/beans/ContentResponse';
//import {ORGANISATION_ID} from 'src/app/constants/constantVar'


@Injectable({
  providedIn: 'root'
})


export class ChatbotListService {
  // private botNodeData = new BehaviorSubject<NodeData[]>([]);
  // private dataStore: { dataSet: any } = { dataSet: [] };
  // readonly dataSet = this.botNodeData.asObservable();

  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  private httpOptions = {
    headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
    })
  };

// ***************** for cookies ********************
  private cookieSub = new BehaviorSubject(<any> false);
  isCookieValAvail = this.cookieSub.asObservable();

  setCookieVal(data : any){
    this.cookieSub.next(data);
  }
  // ***************** for cookies ********************

  chatBotlist(orgId): Observable<any> {
    return this.http.get(GET_WORKFLOW.toString()+"?organisationId="+orgId,this.httpOptions);
  }

  getchatBot(chatBotForm: any): Observable<any> {
    return this.http.post(GET_CHAT_BOT.toString(), chatBotForm,this.httpOptions);
  }

  delById(id: any): Observable<any> {
    return this.http.delete(DEL_CHATBOT_BY_ID.toString()+"?id="+id,this.httpOptions);
  }

  pauseById(id: any): Observable<any> {
    return this.http.get(PAUSE_WORKFLOW.toString()+"?id="+id,this.httpOptions);
  }

  resumeById(id:any):Observable<any>{
    return this.http.get(RESUME_WORKFLOW.toString()+"?id="+id,this.httpOptions);
  }

  botToEdit(id: any , orgId:any): Observable<any> {
    return this.http.get(BOT_EDIT.toString()+id+"?organisationId="+orgId,this.httpOptions);
  }

  updatechatBot(updatechatBotForm: any): Observable<any> {
    return this.http.put(UPDATE_WORKFLOW.toString(),updatechatBotForm,this.httpOptions);
  }
  // getAllNodes(id:any): Observable<any> {
  //   return this.http.get(GET_ALL_NODE.toString()+id);
  // }
  getchatBotList(id: any , orgId:any): Observable<any> {
    return this.http.get<ContentResponse>(GET_CHATBOT_LIST_USER.toString()+id+"?organisationId="+orgId);
  }
  orgId(orgId): Observable<any> {
    return this.http.get(GET_DEPARTMENTS_NEW.toString()+"?organisationId="+orgId);
  }

  validateCookie(obj): Observable<any> {
    return this.http.post(VALIDATE_TOKEN.toString() , obj);
  }

  // channelId(): Observable<any> {
  //   return this.http.get(this.baseUrl + '/workflowbuilder/api/v1/Ezibot/rest/channel/?organisationId='+ ORGANISATION_ID);
  // }
}
