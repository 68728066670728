export class entryArgs{

    private text:any;

    private validator:any;
    /**
     * Getter $text
     * @return {any}
     */
	public get $text(): any {
		return this.text;
	}

    /**
     * Getter $validator
     * @return {any}
     */
	public get $validator(): any {
		return this.validator;
	}

    /**
     * Setter $text
     * @param {any} value
     */
	public set $text(value: any) {
		this.text = value;
	}

    /**
     * Setter $validator
     * @param {any} value
     */
	public set $validator(value: any) {
		this.validator = value;
	}
   

}