import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { DialogConfig } from '../dialog/dialog-config';
import { DialogRef } from '../dialog/dialogRef';
import { NodeContainerComponent } from '../node-container/node-container.component';
import { EventListener } from '@angular/core/src/debug/debug_node';
import { CommonService } from '../services/common.service';
import { IfStmt } from '@angular/compiler';
import { NodeJson } from '../beans/nodeJson'
import { NodesService } from '../services/nodes/nodes.service';
import { Data } from '../beans/Data';
import { NodeData } from '../beans/nodeData';
import { input } from '../beans/input';
import { actions } from '../beans/actions';
import { links } from '../beans/links';
import { link } from '../beans/link';
import { context_variables } from '../beans/context_variables';
import { DelayTime } from '../beans/delayTime';
import { USER_PROPERTY, OPERATOR_TYPE, MINUTE_SECOND_ARRAY, HOUR_ARRAY,USER_INPUT_ARRAY,USER_INPUT_ARRAY_FOR_WTAPP,USER_ATTRIBUTE_ARRAY, GOAL_UNIT_ARRAY } from 'src/app/constants/constantVar';
import { ConditionalArgs } from '../beans/conditionalArg';
import { OptionObj } from '../beans/optionsObj';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../services/modal.service';
import { actionObjectUpdate } from '../utils/action';
import { nextNode } from '../beans/nextNode';
import { entryArgs } from '../beans/entryArgs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BotdataService } from '../services/botdata.service';
import { endpointManage } from '../utils/endpointManage';
import {checkBranching} from '../utils/checkBranching';
import { node_type_map,node_sub_type_map } from 'src/app/constants/constantVar';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less']
})
export class ModalComponent {
  
  @Input() node: Node;
  
  nodeId: any = "";
  update: boolean = false;
  nodeUpdateData: any;
  private nodeJson: NodeJson;
  private userProperty: String[] = USER_PROPERTY;
  private operatorType: String[] = OPERATOR_TYPE;
  selectOperator: any = 'Select Operator Type';
  private propertyName: any;
  dataMap = new Map();
  item_array: [];
  private propertyNameArr: any = [];
  bot_id: any;
  bot_name:any;
  bot_group_name:string;
  updateInputData: any;
  nextNodeId: string = null;
  nextNodeYes: string = null;
  nextNodeNo: string = null;
  moreDivArr: any = [];
  nodeUpdateDataAll: any;
  updateVar:boolean=false;
  arrayLink:any[];
  channelLabel:string;
  branching:boolean=false;
  positionTop:any=0;
  //**if else 
  ifelseStrCon:boolean = false;
  ifElseConOp :boolean = true;
  ifElseLoopContinue : boolean = true;
  ifElseUpdatedData:boolean = false;
  conditionString : String = ""; 
  ifElseConditionString : String = "";
  //**
  delayTotal:boolean
  Textname:any='';
  input_regex:any='';
  regex_req:boolean=false;
  orgId:any;
  loopCounter:number =1;
  organizationId:any;
  clickableNode:any;
  nodeTypeMap: object = node_type_map;
  nodeSubTypeMap: object = node_sub_type_map;
  
  constructor(public config: DialogConfig, public dialog: DialogRef,
    private commonService: CommonService, private nodeServices: NodesService, 
    private activeRoute:ActivatedRoute,private toastr: ToastrService,
    private modelService: ModalService , private botDataService: BotdataService) {
      
      this.channelLabel = sessionStorage.getItem("channel_label");
      this.bot_name = sessionStorage.getItem("bot_name");
      this.organizationId = sessionStorage.getItem("botifier-workflow-org-id");
      
      if (this.config.data.edit_data && !this.config.data.node_type_clickable) {
        this.bot_id = this.config.data.edit_data.bot_id;
        this.nodeId = this.config.data.edit_data.id;
        this.update = true;
        this.updateVar =true;
        this.nodeUpdateData=this.config.data.edit_data;
        this.nodeName = this.config.data.edit_data.node_name;
        this.selected_node_type = this.config.data.edit_data.node_sub_type;
        this.config.data.node_type = this.config.data.edit_data.node_type;
        if(this.config.data.edit_data.goal_name) {
          this.isGoalDefined = true;
          this.goalName = this.config.data.edit_data.goal_name;
          this.goalValue = this.config.data.edit_data.goal_value;
          this.goalUnit = this.config.data.edit_data.goal_unit;
          this.goalReadOnly = true;
        }
        this.updateDataAfter(this.config.data.edit_data.node_data);
      } else {
        this.update = false;
        this.bot_id = this.config.data.bot_id;
      }
      if(this.config.data.node_type_Dummy){
        this.bot_id = this.config.data.edit_data.bot_id;
        this.nodeId = this.config.data.edit_data.id;
        this.update = false;
        this.updateVar =true;
        this.updateDataAfter(this.config.data.edit_data.node_data);
        
      }
      if(this.config.data.node_type_clickable){
        this.bot_id = this.config.data.edit_data.bot_id;
        this.nodeId = this.config.data.edit_data.id;
        this.update = false;
        this.clickableNode=this.config.data.edit_data
       
        //this.updateDataAfter(this.config.data.edit_data.node_data);
        
      }
      this.bot_group_name = sessionStorage.getItem("channel_group_name");
    }
    selected_node_type: any = 'Select Node type';
    show_response: any = false;
    showCart: boolean = false;
    showvariable = false;
    userAtrribute = false;
    selectedOption = "MANUAL";
    showMultiCheckValue: boolean = false;
    show_content: boolean = true;
    show_field: any;
    outboundselect: string = '';
    TextMessage: "";
    compaignType: any = '';
    key: "";
    value: "";
    apiType: "";
    webhookEndPoints: "";
    api_data: any = [];
    MessageSelect: String = "Select Value";
    numberVal: any = "";
    incoming_variable: "";
    userInputAction: any = '';
    validation_select: '';
    nodeName: string;
    Editdata: {};
    input: {}
    data: any = {} = '';
    form: any = [];
    action_array: any = [];
    input_array: any = []
    variable_array: any = [];
    incoming_variable_array = [];
    templateArray: any = [];
    compaignArray: any = [];
    validationArray: any = ['Number', 'Mobile Number', 'Full Name', 'Email', 'Free Text', 'Regex']
    sortOrders: string[] = ['Equals', 'Starts_With', 'End_With', 'Contains', 'Regex']
    selectMessage: string[] = ["Text", "Template", "Campaign"];
    apiArray: any = [];
    inputMsg: any;
    inputMsgDelay: any;
    userInputArray=USER_INPUT_ARRAY;
    userInputArrayForWtapp=USER_INPUT_ARRAY_FOR_WTAPP;
    delayhour: any = "Select Hour";
    delayminute: any = "Select Minute";
    delaysecond: any = "Select Second";
    userproperty: any = "Select User Property";
    conditionalTab: any = "Normal";
    advanceText : any = "";
    normalText : any = "";
    Textvalue: any = '';
    inputObject: {};
    positionLeft:any;
    contextVariableObj: {};
    minuteSecArray = MINUTE_SECOND_ARRAY;
    hourArray = HOUR_ARRAY;
    loopCounterArray = [1,2,3,4,5];
    delayActive: boolean = false;
    name = '';
    isResponse:boolean=false;
    isOptionEmpty:boolean=false;
    args: any = {};
    isloading: boolean = false;
    storeVarMap = new Map();
    dataApiAndWebId: any;
    val_error:boolean=false;
    isOptionDuplicate:boolean=false;
    node_name_req:boolean=false;
    user_attr_array=USER_ATTRIBUTE_ARRAY;
    user_attr_select:any='';
    user_arttr_req:boolean=false;
    extraction_path:any="result";
    key_field:any="dn";
    value_field:any="value";
    IfElseContextVariableArray:any=[];
    filterContextVarArray:any=[];
    isGoalDefined: boolean = false
    goalName: string
    goalValue: string
    goalUnit: string  = "Select Goal Unit"
    private goalUnitArray: String[] = GOAL_UNIT_ARRAY;
    goalNameReq: boolean = false
    goalReadOnly: boolean = false


    
    onClose() {
      this.dialog.close('some value');
    }
    openType(selectedType) {
      // this.bot_id = this.config.data.bot_id;
      this.show_content = !this.show_content;
      this.config.data.node_dummy= true;
      this.selected_node_type = selectedType;
      
      
      if (this.selected_node_type == 'API' || this.selected_node_type == 'USER_INPUT' ) {
        this.nodeServices.getApis(this.organizationId).subscribe(response => {
          this.apiArray = response.result;
          let filter = this.apiArray.filter(x => x.id == this.dataApiAndWebId);
          this.data = filter[0];
        }, error => {
        });
      } else if (this.selected_node_type == 'IF_ELSE' && this.config.data.node_type_Dummy != "DUMMY") {
        this.ifelseStrCon = false; 
        // this.ifElseConOp = true;
        this.ifElseLoopContinue = true;
        // this.bot_id = this.config.data.bot_id;
        this.nodeServices.getUserProperty(this.bot_id).subscribe((response) => {
          let upa = response['result'];
          if (upa.length > 0) {
            upa.forEach(value => {
              let val = value.split(".");
              this.propertyNameArr.push(val[2]);
            })
          } else {
            this.propertyNameArr = [];
          }
        }, (err) => {
          this.propertyNameArr = [];
        })
        this.nodeServices.getContextVariable(this.bot_id,this.organizationId).subscribe((response)=>{
          if(response){
              this.IfElseContextVariableArray=response['result'];
              this.filterContextVarArray= this.IfElseContextVariableArray;
          }
      },error=>{
          
      });
      }
      else if (this.selected_node_type == 'WEBHOOK') {
        this.nodeServices.getwebhook(this.organizationId).subscribe(response => {
          this.apiArray = response.result;
          let filter = this.apiArray.filter(x => x.id == this.dataApiAndWebId);
          this.data = filter[0];
        }, error => {
        });
      }
      else if (this.selected_node_type == 'WORKFLOW_TRIGGER') {
        this.modelService.getAllWorkflow(this.organizationId).subscribe(response => {
          this.apiArray = response.result.bots;
          let filter = this.apiArray.filter(x => x.id == this.dataApiAndWebId);
          this.apiArray = this.apiArray.filter(x => x.is_active && x.name != this.bot_name);
          this.data = filter[0];
        }, error => {
        });
      }
      else if (this.selected_node_type == 'DEPARTMENT_SWITCHER') {
        this.modelService.getDepartment(this.bot_id , this.organizationId).subscribe(response => {
          this.apiArray = response.result;
          let filter = this.apiArray.filter(x => x.id == this.dataApiAndWebId);
          this.data = filter[0];
        }, error => {
        });
      }
    }
    
    updateDataAfter(update) {
      this.nodeUpdateDataAll=update;
      this.moreDivArr = [];
      
      if(update.context_variables){
        if(update.context_variables.global == true){
          this.showvariable=true;
          update.context_variables.values.forEach((val,key)=>{
            this.key = val;
            this.storeVarMap.set(key, val);
          })
          
          this.incoming_variable_array = update.context_variables.values;
        }else if(update.context_variables.global == false){
          this.showvariable=false;
          update.context_variables.values.forEach((val,key)=>{
            this.key = val;
            this.storeVarMap.set(key, val);
          })
          
        }
        this.userAtrribute=update.context_variables.user_attribute;
        this.user_attr_select=update.context_variables.user_attribute_key;
        this.variable_array=update.context_variables.values;
        this.incoming_variable_array = update.context_variables.values;
        if(this.variable_array.length == 0 || this.variable_array[0] =='' || this.variable_array[0] ==null )
        {
          this.updateVar=false;
        }
        
      }
      if (this.nodeUpdateDataAll.entry_args) {
        this.TextMessage = this.nodeUpdateDataAll.entry_args.text;
        this.MessageSelect = this.nodeUpdateDataAll.entry_args.validator
      }
      if (this.nodeUpdateDataAll.goal_args) {
        this.isGoalDefined = true;
        this.goalName = this.nodeUpdateDataAll.goal_args.name
        this.goalValue = this.nodeUpdateDataAll.goal_args.value
        this.goalUnit = this.nodeUpdateDataAll.goal_args.unit
      }
      if (this.selected_node_type == "USER_INPUT") {
        if (this.nodeUpdateDataAll.outbound) {
          this.showCart = true;
          for (let i of this.nodeUpdateDataAll.outbound) {
            this.inputMsg = i.data.text;
          }
          this.UserInputOutbound(this.inputMsg, this.inputMsgDelay);
        }
        if (this.nodeUpdateDataAll.inbound) {
          this.input_regex=this.nodeUpdateDataAll.inbound.regex;
          if (this.nodeUpdateDataAll.inbound.input) {
            this.MessageSelect=this.nodeUpdateDataAll.inbound.input.type;
            this.branching=this.nodeUpdateDataAll.inbound.input.branching;
            if(this.nodeUpdateDataAll.inbound.input.type == "Dropdown")
            {
              this.userInputAction ="Dropdown";
              if (this.nodeUpdateDataAll.inbound.input.mutli_select == true) {
                this.showMultiCheckValue = true;
                
              }
            }
            else if(this.nodeUpdateDataAll.inbound.input.type == "Buttons")
            {
              this.userInputAction="Button";
              if (this.nodeUpdateDataAll.inbound.input.mutli_select == true) {
                this.showMultiCheckValue = true;
                
              }
            }
            else
            {
              this.userInputAction = this.nodeUpdateDataAll.inbound.input.type;
              
            }
            if(this.userInputAction == 'Dropdown' || 
            this.userInputAction == 'Button')
            {
              this.action_array=[];
              this.nodeServices.getApis(this.organizationId).subscribe(response => {
                this.apiArray=response.result;
              }, error => {
              });
            }
            this.action_array = this.nodeUpdateDataAll.inbound.input.options
            if (this.nodeUpdateDataAll.inbound.input.selection_option == "FETCH_VIA_API") {
              this.variable = true;
            }
            if(this.nodeUpdateDataAll.inbound.input.selection_option!='')
            {
              this.selectedOption=this.nodeUpdateDataAll.inbound.input.selection_option;
            }
            else{
              this.selectedOption="MANUAL";
            }
            
            if(this.nodeUpdateDataAll.inbound.input.end_point_args){
              if(this.nodeUpdateDataAll.inbound.input.end_point_args.extraction_path !=='' && this.nodeUpdateDataAll.inbound.input.end_point_args.extraction_path !== undefined && this.nodeUpdateDataAll.inbound.input.end_point_args.extraction_path !== null ) 
              {
                this.extraction_path=this.nodeUpdateDataAll.inbound.input.end_point_args.extraction_path;
              }
              else{
                this.extraction_path='result';
              }
              if(this.nodeUpdateDataAll.inbound.input.end_point_args.key_field !=='' && this.nodeUpdateDataAll.inbound.input.end_point_args.key_field !== undefined && this.nodeUpdateDataAll.inbound.input.end_point_args.key_field !== null)
              {
                this.key_field=this.nodeUpdateDataAll.inbound.input.end_point_args.key_field;
              }
              else{
                this.key_field='dn';
              }
              if(this.nodeUpdateDataAll.inbound.input.end_point_args.value_field !=='' && this.nodeUpdateDataAll.inbound.input.end_point_args.value_field !== undefined && this.nodeUpdateDataAll.inbound.input.end_point_args.value_field !== null)
              {
                this.value_field=this.nodeUpdateDataAll.inbound.input.end_point_args.value_field;
              }
              else{
                this.value_field='value';
              }
            }
            
            if (this.nodeUpdateDataAll.inbound.input.end_point_args.data) {
              let abc = this.nodeUpdateDataAll.inbound.input.end_point_args.data;
              for (const [name, value] of Object.entries(abc)) {
                this.api_data.push({ name, value });
              }
              if( this.api_data)
              {
                this.PushToApiCart();
              }
            }
            this.dataApiAndWebId = this.nodeUpdateDataAll.inbound.input.end_point_args.id;
            if (this.dataApiAndWebId) {
              this.args.id=this.dataApiAndWebId;
              this.openType(this.selected_node_type)
              
            }
            
          }
          
        }
      } else {
        if (this.nodeUpdateDataAll.inbound) {
          this.showCart = true;
          this.validation_select = this.nodeUpdateDataAll.inbound.validator;
          this.input_regex = this.nodeUpdateDataAll.inbound.regex;
        }
        if (this.nodeUpdateDataAll.outbound) {
          this.showCart = true;
          this.action_array = this.nodeUpdateDataAll.outbound;
          if (this.selected_node_type == 'OUTBOUND') {
            for (var list of this.action_array) {
              if(!list.data.delay) {
                list.data.delay = 1;
              }
            }
          }
        }
      }
      
      if (this.nodeUpdateDataAll.wait_for_user_response) {
        this.showCart = true;
        this.show_response = this.nodeUpdateDataAll.wait_for_user_response;
      }
      if (this.nodeUpdateDataAll.end_point_args) {
        this.showCart = true;
        if (this.nodeUpdateDataAll.end_point_args.data) {
          let abc = this.nodeUpdateDataAll.end_point_args.data;
          for (const [name, value] of Object.entries(abc)) {
            this.api_data.push({ name, value });
          }
          if( this.api_data)
          {
            this.PushToApiCart();
          }
        }
        this.dataApiAndWebId = this.nodeUpdateDataAll.end_point_args.id;
        this.args.id=this.dataApiAndWebId;
        if (this.dataApiAndWebId) {
          this.openType(this.selected_node_type)
          
        }
        
      }
      if (this.nodeUpdateDataAll.dept_switcher_args) {
        this.dataApiAndWebId = this.nodeUpdateDataAll.dept_switcher_args.id;
        if (this.dataApiAndWebId) {
          this.openType(this.selected_node_type)
          
        }
        
      }
      
      if (this.nodeUpdateDataAll.workflow_trigger_args) {
        this.dataApiAndWebId = this.nodeUpdateDataAll.workflow_trigger_args.id;
        if (this.dataApiAndWebId) {
          this.openType(this.selected_node_type)
          
        }
        
      }
      if(this.nodeUpdateDataAll.condition_args){
         
        this.ifelseStrCon = false; 
        this.ifElseConOp = false;
        this.ifElseLoopContinue = true;
        let condition = this.nodeUpdateDataAll.condition_args.condition;
        this.conditionalTab = this.nodeUpdateDataAll.condition_args.mode;
        if(this.conditionalTab == 'Normal'){  
         
          // this.openType(this.selected_node_type)
          if(!condition.includes('((') && !condition.includes('( (')){
            
           setTimeout(() => {
            this.nodeServices.Loading=true;
         
            this.nodeServices.getContextVariable(this.bot_id,this.organizationId).toPromise().then((response)=>{
                
              if(response){
                 
                  this.IfElseContextVariableArray=response['result'];
                  this.filterContextVarArray= this.IfElseContextVariableArray;
                  
                   this.advanceStringToNormalArray(condition);
                   this.nodeServices.Loading=false;
                  
                    
                   
              } 
          },error=>{
              
          });
           },0);
           
            // setTimeout(() => {
            //   this.advanceStringToNormalArray(condition);      
            // 
                  
          }else{
            this.normalText = "Invalid Format for Normal Condition";
            this.advanceText = condition;
            this.moreDivArr = [];
            this.action_array = [];
          }
        }else if(this.conditionalTab == 'Advance'){
          this.advanceText = condition;
          this.moreDivArr = [];
          this.action_array = [];
        }
        
        
      }
      if(this.nodeUpdateDataAll.delay_args){
        this.delayhour = this.nodeUpdateDataAll.delay_args.hour;
        this.delayminute = this.nodeUpdateDataAll.delay_args.minute;
        this.delaysecond = this.nodeUpdateDataAll.delay_args.second;
      }
      if(this.nodeUpdateDataAll.wait_until_args){
        this.MessageSelect = this.nodeUpdateDataAll.wait_until_args.validator
        this.TextMessage = this.nodeUpdateDataAll.wait_until_args.text
      }
      if(this.nodeUpdateDataAll.counter_value){
        this.loopCounter = this.nodeUpdateDataAll.counter_value;
        
      }
    }
    
    
    enableBranching(){
      
      this.branching=!this.branching;
      
    }
    ifElseContolType:any='';
    ifElseContolUpdateType:any='';
    dataOptionArray:any=[];

    changeContextVariable(value,list,updateList,type){
      if(type=='input'){
          this.ifElseContolType=list.control_type
          this.propertyName=value;
      }

      if(type=='update'){
          this.ifElseContolUpdateType=list.control_type
          updateList.property=value;
          if(list.control_type=='Buttons' || list.control_type=='Dropdown'){
           updateList.operatorType='equals';
          }
      }
      if(this.ifElseContolType=='Buttons' || this.ifElseContolType=='Dropdown'){
        this.nodeServices.getDataOptions(list.node_id,this.organizationId).toPromise().then((response)=>{
            if(response){
              if(response.statusCode==200){
                this.dataOptionArray=response.result;
              }
                // this.filterDataOptionArray=this.dataOptionArray;
            }
        },error=>{
            
        });
        
    }
      
  }
    changedataOption(value,list,type){
      console.log(value);
      if(type=='input')
      this.numberVal=value;
      else if(type=='update'){
        list.value=value;
      }
    }
    selectConditionalTab(item) {
      this.conditionalTab = item;
      if(item == 'Advance'){
        if((this.normalText != "Invalid Format for Normal Condition" || !this.normalText)){
          this.conditionString = this.createConditionalString();
          if(this.conditionString){
            this.advanceText = this.conditionString;
          }
        }else{
          this.conditionString = this.advanceText;
        }      
      }else if(item == 'Normal'){ 
        this.normalText = "";
        let condition = this.advanceText ;
        if(!condition.includes('((') && !condition.includes('( (')){
          this.ifElseLoopContinue = true;
          this.advanceStringToNormalArray(condition);
        }else{
          this.normalText = "Invalid Format for Normal Condition";
        }          
      }
    }
    
    advanceStringToNormalArray(condition){
      this.action_array = [];
      this.moreDivArr = [];
      if(condition.includes('AND') && condition.includes('OR')){
        let andData = condition.split("AND");
        andData.forEach((val,key)=>{
          this.form = [];
          if(val.includes('OR')){
            let orData = val.split("OR");
            orData.forEach((val1,key1)=>{
              this.form = [];
              if((orData.length-1) == key1){
                this.splitConditionalString(val1 , "AND");
              }else  if(key1 < (orData.length-1)){
                this.splitConditionalString(val1 , "OR");
              }
            })
          }else{
            this.splitConditionalString(val , "AND");
          }    
        })
      }else if(condition.includes('AND') && !condition.includes('OR')){
        let andData = condition.split("AND");
        andData.forEach((val,key)=>{
          this.form = [];
          this.splitConditionalString(val , "AND");       
        })
      }else if(!condition.includes('AND') && condition.includes('OR')){
        let orData = condition.split("OR");
        orData.forEach((val,key)=>{
          this.form = [];
          this.splitConditionalString(val , "OR");           
        })
      }else if(!condition.includes('AND') && !condition.includes('OR')){
        this.form = [];
        this.splitConditionalString(condition , "OR");           
      }
      
      this.action_array[(this.action_array.length)-1]['validationType'] = null;
      let arr = this.moreDivArr;
      arr[(arr.length)-1].validationType = "Select Operator Type";
      this.MessageSelect = arr[(arr.length)-1].operatorType;
      this.selectOperator = arr[(arr.length)-1].validationType;
      this.propertyName = arr[(arr.length)-1].property;
      this.numberVal = arr[(arr.length)-1].value;
      this.moreDivArr.pop();

      // for context vriable
      let row= this.filterContextVarArray.filter(x=>x.context_value==this.propertyName);
            if(row.length > 0 && row[0].context_value==this.propertyName && (row[0].control_type=='Buttons' || row[0].control_type =='Dropdown') ){
              this.ifElseContolType=row[0].control_type
              this.nodeServices.getDataOptions(row[0].node_id,this.organizationId).toPromise().then((response)=>{
                if(response){
                  if(response.statusCode==200){
                    this.dataOptionArray=response.result;
                  }
                }
              });
            }else{
              this.ifElseContolType='others'
            }
 }
    
    splitConditionalString(val , op){
      if(val || this.filterContextVarArray.length>0){ 
        let con_op = this.callForOperator(val);
        let arr = val.replace(/[()]/g, '').split(con_op);   
        if(arr[0]){
          if(arr[0].includes('{{') || arr[0].includes('{ {')){
            this.form.property = arr[0].trim();
            let row = this.filterContextVarArray.filter(x=>x.context_value==this.form.property);
            if(row.length > 0 && row[0].context_value==this.form.property && (row[0].control_type=='Buttons' || row[0].control_type =='Dropdown') ){
              this.form.control_type=row[0].control_type
              this.nodeServices.getDataOptions(row[0].node_id,this.organizationId).toPromise().then((response)=>{
                if(response){
                  if(response.statusCode==200){
                    this.dataOptionArray=response.result;
                  }
                }
            },error=>{
            });
            }
            else{
              this.form.control_type='others'
            }
            
          }else{
            this.form.property  = arr[0].trim().replace(/'/g, '');
            let row= this.filterContextVarArray.filter(x=>x.context_value==this.form.property);
            if(row.context_value==this.form.property && (row.control_type=='Buttons' || row.control_type =='Dropdown') ){
              this.form.control_type=row.control_type
              this.nodeServices.getDataOptions(row[0].node_id,this.organizationId).toPromise().then((response)=>{
                if(response){
                  if(response.statusCode==200){
                    this.dataOptionArray=response.result;
                  }
                }
            },error=>{
            });

            }else{
              this.form.control_type='others'
            }
          }
        }  
        if(con_op){
          this.form.operatorType = con_op.trim();
        }
        this.form.validationType = op;
        
        if(arr[1]){
          if(arr[1].includes('{{') || arr[1].includes('{ {')){
            this.form.value = arr[1].trim();
          }else{
            this.form.value = arr[1].trim().replace(/'/g, '');
          }
        } 
        this.moreDivArr.push(this.form);
        this.action_array.push(this.form);
        
      }
    }
    filterContextVar(value,list, index ,type){
      let new_array=[]
      if(this.propertyName=='' || value==''){
          this.filterContextVarArray=this.IfElseContextVariableArray;
      }
      else{
          new_array=[];
          for(var i=0; i<this.IfElseContextVariableArray.length; i++)
          {
              if(this.IfElseContextVariableArray[i].context_key.search(value) !==-1)
              {
                  new_array.push(this.IfElseContextVariableArray[i]);
              }
              else{
                  this.filterContextVarArray=[];
              }
          }
          if(new_array.length>0){
              this.filterContextVarArray=new_array;
          }
      } 
      if(type=='update')  {
        this.pushUserPropertyInfo(index,list,'')
      }       
  }
    
    callForOperator(val){
      let condition_op : string = null;
      if(val){
        if(val.includes("equals")){
          condition_op = "equals";
        }else if(val.includes("starts_with")){
          condition_op = "starts_with";
        }else if(val.includes("end_with")){
          condition_op = "end_with";
        }else if(val.includes("contains")){
          condition_op = "contains";
        }else if(val.includes("regex")){
          condition_op = "regex";
        }
      }else{
        console.log("error");
      }
      return condition_op;
      
    }
    
    
    UserInputOutbound(inputMsg, inputMsgDelay)
    {
      this.input_array=[];
      this.form = [];
      if (inputMsg != '' && inputMsg != undefined ) {
        this.show_response = true;
        let input = new Map();
        input.set("text", inputMsg)
        let delay = inputMsgDelay == undefined || inputMsgDelay == null || inputMsgDelay < 1 ? 1 : inputMsgDelay;
        if (delay > 20) {
          delay = 20;
        }
        input.set("delay", delay)
        this.inputMsgDelay = delay
        this.form.data = [...input].reduce((o, [key, value]) => (o[key] = value, o), {})
        this.form.type = "Text";
        this.input_array.push(this.form);
      }
      if (this.key) {
        this.variable_array=[];
        let context: string = this.key;
        this.variable_array.push(context);
      }
      
    }
    
    storeVariable(income, index) {
      this.storeVarMap.set(index, income);
      // this.storeVarMap.forEach((value , key) => {
      //     this.variable_array.push(value);
      // });    
    }
    
    countVariable() {
      let op = this.MessageSelect;
      let msg: any = this.TextMessage;
      
      if ((msg != "" && msg != undefined) && op != 'Select Value') {
        if (op == "Regex") {
          var regex1 = RegExp("\\(.*?\\)", "g");
          let varArr = this.TextMessage.match(regex1);
          
          if (varArr != null) {
            this.incoming_variable_array.length = varArr.length;
            this.showCart = true;
          } else {
            this.incoming_variable_array.length = 0;
            this.showCart = false;
          }
        }
      } else {
        this.incoming_variable_array.length = 0;
        this.showCart = false;
      }
      
    }
    
    changeIncomeMessage(newSortOrder: string, value , list) {
      
      
      if(list != undefined && list != null){
        list["operatorType"] = value;
      }
      else{
        this.userInputAction = newSortOrder;
        this.MessageSelect = value;
        this.ifElseConOp = false;
      }
      if(this.selected_node_type == 'USER_INPUT'){
        if(this.userInputAction == 'Dropdown' || 
        this.userInputAction == 'Button')
        {
          this.branching=this.branching;
          this.action_array=this.action_array;
          this.nodeServices.getApis(this.organizationId).subscribe(response => {
            this.apiArray=response.result;
          }, error => {
          });
        }
        if(this.MessageSelect != 'Dropdown' && this.MessageSelect != 'Buttons')
        {
          console.log(this.MessageSelect);
          
          this.branching=false;
          //this.action_array=[];
          this.args={};
          this.data='';
          this.api_data=[];
          this.variable=false;
          this.selectedOption='MANUAL';
          this.showMultiCheckValue=false;
        }
      }
      
    }
    
    changeOperatorType(item: any , list) {
      if(list != undefined && list != null){
        list["validationType"] = item;
      }else{
        this.selectOperator = item;
      }
      
    }
    
    charonly(event: any) {
      const pattern = /^[a-zA-Z0-9_-]+$/;
      let inputChar = String.fromCharCode(event.charCode); if (event.keyCode != 8 && !pattern.test(inputChar)) { event.preventDefault(); }
    }
    
    extractInput(event: any) {
      const pattern = /^[a-zA-Z0-9\[\]._-]+$/;
      let inputChar = String.fromCharCode(event.charCode); 
      if (event.keyCode != 8 && !pattern.test(inputChar)) 
      { event.preventDefault(); }
    }
    
    contextKey(event:any){
      const pattern = /^[a-zA-Z0-9._-]+$/;
      let inputChar = String.fromCharCode(event.charCode); 
      if (event.keyCode != 8 && !pattern.test(inputChar)) 
      { event.preventDefault(); }
    }
    removeSpace(event:any , type:any){
      let val =  event.target.value;
      val = val.replace(/ /g,'');
      if(type=="nodeName"){
        this.nodeName = val;
      }else if(type="key"){
        this.key = val;
      }
    }
    
    
    
    changeOutbound(message: any) {
      this.show_field = message;
      this.outboundselect = message;
      if (this.outboundselect == 'Template') {
        this.nodeServices.getTemplate(this.bot_id , this.organizationId).subscribe(response => {
          this.templateArray = response.result;
        }, error => {
        });
      }
      if (this.outboundselect == 'Campaign') {
        this.nodeServices.getCampaign(this.bot_id , this.organizationId).subscribe(response => {
          this.compaignArray = response.result;
        }, error => {
        });
      }
      if (this.outboundselect == 'Text') {
        this.data = {} = '';
      }
    }
    
    validationChange(item) {
      this.validation_select = item;
    }
    changeApiType(api: any) {
      this.apiType = api;
    }
    UserAttrChange(item)
    {
      this.user_attr_select=item;
    }
    
    variable:boolean=false;
    variabelfield() {
      this.variable = !this.variable;
      if (this.variable == false) {
        // this.api_data = []; 
        // this.args={};
        // this.args.id='';
        // this.selectedOption = "MANUAL";
        // this.data='';
      }
      else {
        // this.selectedOption = "FETCH_VIA_API";
        // this.showMultiCheckValue=false;
        // this.action_array=[];
      }
    }
    showvariabelfield() {
      this.showvariable = !this.showvariable;
      this.addToVariablesArray();
    }
    UserAtrribute()
    {
      this.userAtrribute= !this.userAtrribute;
    }
    
    multiCheck() {
      this.showMultiCheckValue = !this.showMultiCheckValue;
       if (this.showMultiCheckValue == true) {
         this.branching=false;
      //   this.selectedOption = "MANUAL";
      //   this.variable=false;
      //   this.args={};
      //   this.api_data=[];
       }
      // else{
      //   this.action_array=[];
      // }
      
    }
    pushToIncomeVariable() {
      this.form = [];
      this.showCart = true;
      this.form.incoming_variable = this.incoming_variable;
      this.incoming_variable_array.push(this.form);
      this.incoming_variable = '';
    }
    worflowargs: any = '';
    SelectChange(item: any) {
      // this.showError=true;
      this.data = item;
      if (this.selected_node_type == 'API' || this.selected_node_type == 'USER_INPUT' || this.selected_node_type == 'WEBHOOK') {
        this.args.id = item.id;
        if (this.api_data) {
          this.PushToApiCart()
        }
      }
    }
    showError:any=false;
    valuemsg:any='';
    variableValidationCheck()
    {
      for(let row of this.action_array)
      {
        if(row.type == "Template" || row.type == "Campaign")
        {
          for (let list of row.data.variables)
          {
            if((list.default_value == null && list.required) || (list.default_value == '' && list.required))
            {
              this.showError=true;
              return;
            }
            else
            {
              this.showError=false;
            }
          }
        }
      }
    }
    setOptionNameonChange(index,value)
    {
      this.action_array[index].name=value;
    }
    AddOutboundVariablevalue(index)
    {
      
      this.action_array[index].data=this.data;
      console.log(this.action_array); 
      if(this.data && this.selected_node_type == 'OUTBOUND')
      {
        for (let row of this.data.variables)
        {
          if((row.default_value == null && row.required) || (row.default_value == '' && row.required) )
          {
            this.showError=true;
          }
        }
      }
      this.data='';
    }
    PushToCart(index) {
      //this.val_checkDuplicate=true;
      this.addField=false;
      
      this.form = [];
      this.val_error=false;
      this.showError=false;
      if (this.variable == false) {
        this.selectedOption = "MANUAL";
      }
      // if(this.data && this.selected_node_type == 'OUTBOUND')
      // {
      //   for (let row of this.data.variables)
      //     {
      //       if((row.default_value == null && row.required) || (row.default_value == '' && row.required) )
      //       {
      //         this.showError=true;
      //       }
      //     }
      // }
      // if(this.data && index)
      // {
      //   this.action_array[index].data=this.data;
      //   console.log(this.action_array);
      //   return;
      // }
      // if (this.Textvalue == '' && this.data == '')
      // {
      //   this.toastr.error(" Please select the input value");
      // } 
      
      if (this.outboundselect != '' ) {
        this.form = [];
        this.isOptionEmpty=false;
        // this.show_field = this.outboundselect;
        this.showCart = true
        this.form.type = this.outboundselect;
        if (this.outboundselect== 'Text') {
          let input = new Map();
          input.set("text", this.Textvalue)
          this.form.data = [...input].reduce((o, [key, value]) => (o[key] = value, o), {})
        }
        else {
          this.form.data = this.data;
          // this.form.type = this.show_field
        }
        
        this.action_array.push(this.form);
        this.Textvalue = '';
        this.data = ''
        this.outboundselect = '';
      }
      if ((this.userInputAction == 'Dropdown' || this.userInputAction == 'Button') && this.selectedOption == "MANUAL" && (this.Textvalue.trim() != "" || this.Textname.trim() != "")) {
        this.form.dn = this.Textname;
        this.form.value = this.Textvalue;
        //this.action_array.contains(this.form.value);
        this.action_array.push(this.form);
        this.Textvalue = '';
        this.Textname = '';
        this.isOptionDuplicate=false;
        
        //  if(!this.action_array.some((item) => item.value == this.Textvalue)){
        
        //  }else{
        //     this.showInvelidvalidationMsg("Option value can not be duplicate");
        //     this.addField=true;
        //       //this.action_array.push(this.form);
        //      this.isOptionDuplicate=true;
        //     //  this.Textvalue = '';
        //   //this.Textname = '';
        
        //  }
      }else{
        this.isOptionEmpty=true
        if(this.outboundselect != ""){
         this.showInvelidvalidationMsg("Option name can not be empty");
        }
        
      }
      
      this.Textvalue = '';
      this.data = ''
      
    }
    
    validateAndChnageHashMapKey(oldValue,newValue,index){
      if(newValue.trim() != ""){
        console.log(this.action_array);
        
        if(this.nodeUpdateDataAll != null && this.nodeUpdateDataAll != undefined){
          let indexOfDuplicate= this.action_array.findIndex(x => x.value ===oldValue);
         
          if(indexOfDuplicate > index || indexOfDuplicate == -1){
            
            this.nodeUpdateDataAll.next_node.branch_nodes_id[newValue]=this.nodeUpdateDataAll.next_node.branch_nodes_id[oldValue];
            
            delete this.nodeUpdateDataAll.next_node.branch_nodes_id[oldValue];
           
          }          
          
        }
      }else{
        this.showInvelidvalidationMsg("Option name/value can not be empty")
        this.action_array.forEach(element => {
          if(element.value.trim() == "" ){
            element.value=oldValue;
            
          }
        });
      }
    }
    
    validateOptionValues(){
      this.isOptionEmpty=false;
      // let valueArr = this.action_array.map(function(item){ return item.value });
      //  let nameArr = this.action_array.map(function(item){ return item.dn });
      // let isDuplicate = valueArr.some(function(item, idx){ 
      //     return valueArr.indexOf(item) != idx 
      // });
      this.action_array.forEach(element => {
        if((element.value == null || element.value == undefined || element.value.trim()== "")
        || (element.dn == null || element.dn == undefined || element.dn.trim()== "")){
          this.isOptionEmpty=true
          this.showInvelidvalidationMsg("Option name/value can not be empty");
        }
      });
      
      // if(isDuplicate){
      //  this.isOptionDuplicate=true
      //  this.showInvelidvalidationMsg("Option value can not be duplicate");
      // }else{
      //   this.isOptionDuplicate=false
      
      // }
      
    }
    
    showInvelidvalidationMsg(msg)
    {
      this.toastr.error(msg)
    }
    PushToApiCart() {
      this.addField=false;
      this.form = [];
      // this.showCart = true;
      if (this.name || this.value) {
        this.form.name = this.name;
        this.form.value = this.value;
        this.api_data.push(this.form);
        this.name = '';
        this.value = '';
      }
      let apiMap = new Map();
      for (let optiondata of this.api_data) {
        let abc = Object.assign({}, optiondata)
        apiMap.set(abc.name, abc.value);
      }
      
      this.args.data = [...apiMap].reduce((o, [key, value]) => (o[key] = value, o), {});
      if(this.dataApiAndWebId && this.args.id == undefined)
      {
        this.args.id=this.dataApiAndWebId;
      }
      
      
      
    }
    AddOptionalField()
    {
      this.addField=true;
    }
    addField:boolean=false;
    AddField()
    {
      this.addField=true;
      if(this.api_data)
      {
        let apiMap = new Map();
        for (let optiondata of this.api_data) {
          let abc = Object.assign({}, optiondata)
          apiMap.set(abc.name, abc.value);
        }
        this.args.data = [...apiMap].reduce((o, [key, value]) => (o[key] = value, o), {});
        
      }
      if(this.dataApiAndWebId && this.args.id == undefined)
      {
        this.args.id=this.dataApiAndWebId;
      }
    }
    addToVariablesArray() {
      this.variable_array=[];
      this.showCart = true;
      if (this.key) {
        let context: string = this.key;
        this.variable_array.push(context);
      }
      
      if(this.api_data)
      {
        let apiMap = new Map();
        for (let optiondata of this.api_data) {
          let abc = Object.assign({}, optiondata)
          apiMap.set(abc.name, abc.value);
        }
        
        this.args.data = [...apiMap].reduce((o, [key, value]) => (o[key] = value, o), {});
      }
      if(this.dataApiAndWebId && this.args.id == undefined)
      {
        this.args.id=this.dataApiAndWebId;
      }
    }
    removeOutboundCartItem(index) {
      this.showError=false;
      this.action_array.splice(index, 1);
      //this.validateOptionValues()
      
    }
    removeContextVariable(index)
    {
      this.variable_array.splice(index, 1);
    }
    removeApiCartItem(index) {
      this.api_data.splice(index, 1);
      let apiMap = new Map();
      if(this.api_data)
      {
        for (let optiondata of this.api_data) {
          let abc = Object.assign({}, optiondata)
          apiMap.set(abc.name, abc.value);
        }
        this.args.data = [...apiMap].reduce((o, [key, value]) => (o[key] = value, o), {});
      }
      if(this.dataApiAndWebId && this.args.id == undefined)
      {
        this.args.id=this.dataApiAndWebId;
      }
    }
    
    
    
    
    selectUserProperty(item) {
      this.userproperty = item;
    }
    
    selectHour(val: any) {
      this.delayhour = val;
    }
    
    selectMinute(val: any) {
      this.delayminute = val;
    }
    
    selectSecond(val: any) {
      this.delaysecond = val;
    }
    
    
    selectLoopCounter(val: any) {
      this.loopCounter = val;
    }
    
    
    pushUserPropertyInfo(index:number , value, drpVal) {
      this.ifElseLoopContinue = false;
      this.form = [];
      if(index == -1){  
        if(this.ifElseContolType == "Dropdown" || this.ifElseContolType ==="Buttons") 
        {
            this.form.operatorType ='Equals';
            this.form.control_type=this.ifElseContolType;
            this.MessageSelect='Equals';
        }
        else{
          this.form.control_type='others'
        }
        this.form.operatorType = this.MessageSelect;
        this.form.property = this.propertyName;
        this.form.validationType = this.selectOperator;
        this.form.value = this.numberVal;
        
        if(this.action_array.length > this.moreDivArr.length && (this.form.value == "" || this.form.property == "")){
          this.action_array.pop();
          this.action_array.push(this.form);
        }else if (this.form.value != null && this.form.value != undefined
          && this.form.value != "" && this.form.operatorType != 'Select Value' && this.form.property != ""
          && this.form.validationType == "Select Operator Type" && index == -1) {
            this.ifElseLoopContinue = true;
            this.form.validationType = null;
            for(let i of this.action_array){
              if(i['validationType']== undefined || i['validationType'] == null || i['validationType']=='Select Operator Type'){
                this.action_array.pop(i);
              }
            }
            this.action_array.push(this.form);
          }else if (this.form.value != null && this.form.value != undefined
            && this.form.value != "" && this.form.operatorType != 'Select Value'
            && this.form.property != ""
            && (this.form.validationType != "Select Operator Type" && this.form.validationType != null) && index == -1) {
              this.ifelseStrCon = false; 
              this.ifElseConOp = true;
              this.ifElseLoopContinue = true;
              for(let i of this.action_array){
                if(i['validationType']== undefined || i['validationType'] == null || i['validationType']=='Select Operator Type'){
                  this.action_array.pop(i);
                }
              }
              this.action_array.push(this.form);     
              this.moreDivArr.push(this.form);
              this.MessageSelect = 'Select Value';
              this.numberVal = "";
              this.userproperty = "Select User Property";
              this.selectOperator = 'Select Operator Type';
              this.propertyName = "";
            }
            
          }else if(index > -1){
            
            if(value.value && value.property){
              this.ifElseLoopContinue = true;
            }else{
              this.ifElseLoopContinue = false;
            }
            if(drpVal!==undefined && (drpVal.control_type=="Buttons" || drpVal.control_type=='Dropdown') ){
            this.form.operatorType ='Equals';
            }
            else{
              this.form.operatorType = value.operatorType;
            }
            if(drpVal==undefined && (value.control_type=="Buttons" || value.control_type=="Dropdown")){
              this.form.operatorType = 'Equals';
            }
            this.form.property = value.property;
            this.form.validationType = value.validationType;
            this.form.value = value.value;
            if(drpVal!==undefined){
            this.form.control_type=drpVal.control_type;
            }
            else{
              this.form.control_type=value.control_type;
            }
            this.action_array.splice(index, 1 , this.form);
            this.moreDivArr.splice(index, 1 , this.form);
            this.selectOperator = 'Select Operator Type';
          } 
        }
        
        removeIfElseItem(ind:number){
          this.ifelseStrCon = false; 
          if(ind > -1){
            this.moreDivArr.splice(ind,1);
            this.action_array.splice(ind,1);
          }else if(ind == -1){
            this.action_array.pop();
            let arr = this.moreDivArr[(this.moreDivArr.length)-1];
            this.action_array[(this.action_array.length)-1]['validationType'] = null;
            this.MessageSelect = arr.operatorType;
            this.selectOperator = 'Select Operator Type';
            this.propertyName = arr.property;
            this.numberVal = arr.value;
            this.moreDivArr.pop(); 
          }
        }
        
        
        showresponse() {
          this.show_response = !this.show_response;
          if(this.show_response == false)
          {
            this.showvariable=false;
            this.variable_array=[];
            this.key='';
            this.updateVar=false;
            this.validation_select='';
            this.userAtrribute=false;
            this.user_attr_select='';
          }
        }
        
        pushInputInfo(actionType) {
          let data = new Data;
          if (actionType == "INCOMING_MSG" || actionType == "EVENT") {
            this.showvariable = true;
            this.variable_array = [];
            //let data = new Data;
            let EntryArgs = new entryArgs;
            EntryArgs.$text = this.TextMessage
            EntryArgs.$validator = this.MessageSelect;
            data.$EntryArgs = EntryArgs;
            
            if(EntryArgs.$validator == 'Regex'){
              let len = this.incoming_variable_array.length;
              if(this.storeVarMap.size > 0){
                this.storeVarMap.forEach((value , key) => {
                  if(value != ""){
                    this.variable_array.push(value);
                  }else if(value == ""){
                    this.variable_array.push(null);
                  }
                });
              }
              if( this.variable_array.length != len){
                for(let i=this.variable_array.length;i<len;i++){
                  this.variable_array.push(null);
                }
              }
            }else{
              this.variable_array = [];
            }
            this.dataMap.set("entry_args", data.$EntryArgs)
          }else if (actionType == "OUTBOUND") {
            //let data = new Data;
            let option = Array();
            let inbound = new Map();
            for (let optiondata of this.action_array) {
              if(optiondata.data.delay == null || optiondata.data.delay == 'undefined' || optiondata.data.delay < 1) {
                optiondata.data.delay = 1;
              } else if (optiondata.data.delay > 20) {
                optiondata.data.delay = 20;
              }
              option.push(Object.assign({}, optiondata))
            }
            data.$waitForUserResponse = this.show_response;
            this.dataMap.set("wait_for_user_response", data.$waitForUserResponse)
            data.$outbound = option;
            
            this.dataMap.set("outbound", data.$outbound)
            inbound.set("validation_failure_message", null);
            inbound.set("validator", this.validation_select);
            if(this.validation_select)
            {
              if(this.validation_select=='Regex')
              {
                inbound.set("regex",this.input_regex); 
              }
            }
            let inboundData = [...inbound].reduce((o, [key, value]) => (o[key] = value, o), {})
            this.dataMap.set("inbound", inboundData);
          }else if (actionType == "API" || actionType == "WEBHOOK") {
            //let data = new Data;
            data.$endPointArgsMap = Object.assign({}, ...this.args);
            this.dataMap.set("end_point_args", data.$endPointArgsMap);
          }else if (actionType == "USER_INPUT") {
            if (this.MessageSelect != "Dropdown" &&  this.MessageSelect != "Buttons") {
              // console.log(this.MessageSelect!="Dropdown"  this.MessageSelect != "Buttons")
              console.log("yes");
              
              this.action_array=[];
            }
            
            //let data = new Data;
            let inboundargs = new Map();
            let input = Array();
            for (let data of this.input_array) {
              input.push(Object.assign({}, data))
            }
            data.$outbound = input;
            this.dataMap.set("outbound", data.$outbound);
            if(this.input_array.length)
            {
              data.$waitForUserResponse = this.show_response;
              this.dataMap.set("wait_for_user_response", data.$waitForUserResponse)
            }
            else{
              data.$waitForUserResponse = false;
              this.dataMap.set("wait_for_user_response", data.$waitForUserResponse) 
            }
            
            if(this.variable )
            {
              this.args.extraction_path=this.extraction_path;
              this.args.key_field=this.key_field;
              this.args.value_field=this.value_field;
              if(this.args.id){
                this.selectedOption="FETCH_VIA_API"
                this.action_array=[];
              }
            }
            if(!this.variable && this.action_array.length)
            { 
              this.args={};
              this.selectedOption="MANUAL"
            }
            
            inboundargs.set("validator", null);
            inboundargs.set("validation_failure_message", null);
            if(this.MessageSelect=='Regex')
            {
              inboundargs.set("regex",this.input_regex); 
            }
            let option = Array();
            this.validateOptionValues();
            
            //
            // let valueArr = this.action_array.map(function(item){ return item.value });
            // let isDuplicate = valueArr.some(function(item, idx){ 
            //     return valueArr.indexOf(item) != idx 
            // });
            
            // if(isDuplicate){
            //  this.isOptionDuplicate=true
            // }else{
            //   this.isOptionDuplicate=false
            // }
            for (let optiondata of this.action_array) {
              option.push(Object.assign({}, optiondata))
            }
            let inputargs = new Map();
            inputargs.set("type", this.MessageSelect);
            inputargs.set("mutli_select", this.showMultiCheckValue);
            if (this.MessageSelect == "Dropdown" || this.MessageSelect == "Buttons") {
              inputargs.set("selection_option", this.selectedOption);
              inputargs.set("branching",this.branching);
            }
            else {
              
              inputargs.set("selection_option", "");
              inputargs.set("branching",this.branching);
            }
            
            inputargs.set("end_point_args", Object.assign({}, ...this.args));
            inputargs.set("options", option);
            inboundargs.set("input", [...inputargs].reduce((o, [key, value]) => (o[key] = value, o), {}));
            
            let inboundData = [...inboundargs].reduce((o, [key, value]) => (o[key] = value, o), {})
            this.dataMap.set("inbound", inboundData);
            
          }else if (actionType == 'WORKFLOW_TRIGGER' || actionType == 'DEPARTMENT_SWITCHER') {
            let worflowargs = new Map();
            //let data = new Data;
            if (actionType == 'WORKFLOW_TRIGGER') {
              if(this.data!='' && this.data!=null && this.data!=undefined)
              {
                worflowargs.set("id", this.data.id);
                let workflowData=[...worflowargs].reduce((o, [key, value]) => (o[key] = value, o), {});
                this.dataMap.set("workflow_trigger_args",  workflowData);
              }
            }
            if (actionType == 'DEPARTMENT_SWITCHER') {
              if(this.data!='' && this.data!=null && this.data!=undefined)
              {
                worflowargs.set("id", this.data.id);
                let workflowData=[...worflowargs].reduce((o, [key, value]) => (o[key] = value, o), {});
                this.dataMap.set("dept_switcher_args",  workflowData);
              }
            }    
          }else if(actionType == 'LOOP'){
            data.$counterValue = this.loopCounter;
            this.dataMap.set("counter_value", data.$counterValue);
          }
        }
        
        
        pushInputInfoOfCondNode(actionType) {
          if (this.config.data['node_type'] == 'CONDITIONAL') {
            let actionCond = new Map();
            let data = new Data;
            if (actionType == 'DELAY') {
              let delay = new DelayTime;
              if (this.delayhour != 'Select Hour') {
                delay.$hour = this.delayhour;
              } else {
                delay.$hour = 0;
              }
              if (this.delayminute != 'Select Minute') {
                delay.$minute = this.delayminute;
              } else {
                delay.$minute = 0;
              }
              if (this.delaysecond != 'Select Second') {
                delay.$second = this.delaysecond;
              } else {
                delay.$second = 0;
              }
              
              if(delay.$hour == 0 && delay.$minute == 0 && delay.$second == 0){
                this.delayTotal = true;
              }else{
                this.delayTotal = false;
              }
              
              
              
              data.$delayArgs = delay;
              this.dataMap.set("delay_args", data.$delayArgs);
              
            } else if (actionType == "WAIT_UNTIL") {
              
              let wait = new Map;
              wait.set("validator", this.MessageSelect);
              wait.set("text", this.TextMessage);
              data.$waitUntilArgs = wait;
              let waitObj = [...wait].reduce((o, [key, value]) => (o[key] = value, o), {});
              this.dataMap.set("wait_until_args", waitObj);
              
            } else if (actionType == "IF_ELSE") {
              
              // this.conditionString = "";
              if(this.conditionalTab== "Normal" && (this.normalText != "Invalid Format for Normal Condition" || !this.normalText)){
                this.conditionString = this.createConditionalString();
              }else if(this.conditionalTab == "Advance" && (this.advanceText != "Invalid Format for Advance Condition" || this.advanceText)){
                //this.conditionString = this.advanceText;
                this.normalText="";
                this.advanceStringToNormalArray(this.advanceText);
                this.createConditionalString();
                if(this.ifElseLoopContinue){
                  this.conditionString = this.advanceText;
                }
                
              }    
              let conditionMap = new Map;
              conditionMap.set("condition" , this.conditionString);
              conditionMap.set("mode" , this.conditionalTab);
              data.$conditionArgs = conditionMap;
              let conObj = [...conditionMap].reduce((o, [key, value]) => (o[key] = value, o), {});
              this.dataMap.set("condition_args" , conObj); 
            }
          }
        }
        
        createConditionalString(){
          this.conditionString="";
          this.ifElseConditionString="";
          if(this.conditionalTab == 'Normal'){
            if(this.moreDivArr.length === this.action_array.length){
              //this.conditionString="";
              this.ifElseLoopContinue = false;
            }else{
              if(this.action_array.length >0){
                this.conditionString =  this.makeConditionalString();
              }  
            }    
          }else if(this.conditionalTab == 'Advance'){
            if(this.action_array.length >0){
              this.ifElseLoopContinue = true;
              this.conditionString =  this.makeConditionalString();
            }
          }      
          return this.conditionString;
        }
        
        
        
        makeConditionalString(){
          
          if(this.action_array){
            this.action_array[(this.action_array.length) - 1]['validationType'] = null; 
            this.action_array.forEach((val , key)=> {
              if(val["validationType"] == null || val["validationType"] =='Select Operator Type'){
                if(val["property"].includes('{{') && !val["value"].includes('{{')){
                  this.conditionString += " ("+val["property"]+" "+val["operatorType"].toLowerCase()+" '"+val["value"]+"') ";
                }else if(!val["property"].includes('{{') && val["value"].includes('{{')){
                  this.conditionString += " ('"+val["property"]+"' "+val["operatorType"].toLowerCase()+" "+val["value"]+") ";
                }else if(val["property"].includes('{{') && val["value"].includes('{{')){
                  this.conditionString += " ("+val["property"]+" "+val["operatorType"].toLowerCase()+" "+val["value"]+") ";
                }else if(!val["property"].includes('{{') && !val["value"].includes('{{')){
                  this.conditionString += " ('"+val["property"]+"' "+val["operatorType"].toLowerCase()+" '"+val["value"]+"') ";
                }
              }else if(val["validationType"] == 'AND' || val["validationType"] == 'OR'){
                if(val["property"].includes('{{') && !val["value"].includes('{{')){
                  this.conditionString += " ("+val["property"]+" "+val["operatorType"].toLowerCase()+" '"+val["value"]+"') "+val["validationType"];
                }
                else if(!val["property"].includes('{{') && val["value"].includes('{{')){
                  this.conditionString += " ('"+val["property"]+"' "+val["operatorType"].toLowerCase()+" "+val["value"]+") "+val["validationType"];
                }else if(val["property"].includes('{{') && val["value"].includes('{{')){
                  this.conditionString += " ("+val["property"]+" "+val["operatorType"].toLowerCase()+" "+val["value"]+") "+val["validationType"];
                } 
                else if(!val["property"].includes('{{') && !val["value"].includes('{{')){
                  this.conditionString += " ('"+val["property"]+"' "+val["operatorType"].toLowerCase()+" '"+val["value"]+"') "+val["validationType"];
                }
              }
              
              if(!val['property'] || !val['value']){
                this.ifElseLoopContinue = false;
              }
            })  
          }      
          
          return this.conditionString;
        }
        
        
        
        
        saveData() {
          this.pushInputInfo(this.selected_node_type);
          this.pushInputInfoOfCondNode(this.selected_node_type);
          if (this.update == false) {
            let nodeJson = new NodeJson;
            let nodeData = new NodeData;
            let NextNode = new nextNode;
            let Links = new links;
            
            let contextVariables = new context_variables;
            nodeJson.$bot_id=this.bot_id;
            nodeJson.$node_name = this.nodeName;
            nodeJson.$node_type = this.config.data['node_type'];
            nodeJson.$node_sub_type = this.selected_node_type;
            if (this.showvariable == true) {
              contextVariables.$global = true;
              contextVariables.$values = this.variable_array;
            }else{
              contextVariables.$global = false;
              contextVariables.$values = this.variable_array;
            }
            contextVariables.$user_attribute = this.userAtrribute;
            contextVariables.$user_attribute_key = this.user_attr_select;
            nodeData.$context_variables = contextVariables;
            Links.$x = ""
            Links.$y = [];
            this.arrayLink=[];
            this.arrayLink.push(Links)
            
            nodeData.$links= this.arrayLink;
            NextNode.$node_id = this.nextNodeId;
            NextNode.$yes_node_id = this.nextNodeYes;
            NextNode.$no_node_id = this.nextNodeNo;
            nodeData.$next_node = NextNode;
            this.positionTop= sessionStorage.getItem("post_top");
            this.positionLeft= sessionStorage.getItem("post_left");
            if(this.positionTop != null && this.positionTop != undefined 
              && this.positionLeft != null && this.positionLeft != undefined){
                nodeData.$pos_top = parseInt(this.positionTop) + 150;
                nodeData.$pos_left = parseInt(this.positionLeft)+100;
              }else{
                nodeData.$pos_top = 241;
                nodeData.$pos_left = -55;
              }
              
              
              nodeData.$parent_nodes_type = [];
              if (this.dataMap) {
                for (let [key, value] of this.dataMap) {
                  nodeData[key] = value;
                }
              }
              nodeJson.$node_data = nodeData;
              nodeJson.$flag = true;
              if(nodeJson.$node_name==undefined || nodeJson.$node_name=='')
              {
                this.node_name_req=true;
              }
              else{
                this.node_name_req=false;
                
              }
              if(this.isGoalDefined) {
                let goalMap = this.setGoalArgs();
                nodeJson.$goal_name = goalMap.get("name")
                nodeJson.$goal_value = goalMap.get("value")
                nodeJson.$goal_unit = goalMap.get("unit")
        
                if(nodeJson.$goal_name == undefined || nodeJson.$goal_name == '' || nodeJson.$goal_name == null) {
                  this.goalNameReq = true;
                } else {
                  this.goalNameReq = false;
                }
              } else {
                nodeJson.$goal_name = null
                nodeJson.$goal_value = null
                nodeJson.$goal_unit = null
              }
              if(this.config.data.node_type_clickable){
                nodeJson["clickNode"]=this.clickableNode;
              }
              if(this.checkMandatoryFields(nodeJson.$node_sub_type)){
                this.commonService.setData(nodeJson);
                this.conditionString="";
              }
            }
          }
          
          
          updateData() {
            this.pushInputInfo(this.selected_node_type);
            this.pushInputInfoOfCondNode(this.selected_node_type);
            let nodeJson = new NodeJson;
            let nodeData = new NodeData;
            let NextNode = new nextNode;
            let Links = new links;
            let contextVariables = new context_variables;
            if(this.config.data.node_type_Dummy){
                 nodeJson.$node_name =  this.nodeName;
            }else{
            nodeJson.$node_name =  this.nodeUpdateData['node_name'];
            }
            nodeJson.$bot_id=this.nodeUpdateData['bot_id'];
            
            nodeJson.$node_id=this.nodeUpdateData['node_id'];
            if(this.config.data.node_type_Dummy && this.selected_node_type=="IF_ELSE" || this.selected_node_type=="WAIT_UNTIL" || this.selected_node_type=="DELAY"){
              nodeJson.$node_type = "CONDITIONAL";
            }else{
              nodeJson.$node_type = this.nodeUpdateData['node_type'];
            }
            //nodeJson.$node_type = this.nodeUpdateData['node_type'];
            nodeJson.$id=this.nodeUpdateData['id'];
            nodeJson.$node_sub_type = this.selected_node_type;
            if (this.showvariable == true) {
              contextVariables.$global = true;
              contextVariables.$values = this.variable_array;
            }else  if (this.showvariable == false){
              contextVariables.$global = false;
              contextVariables.$values = this.variable_array;
            }
            contextVariables.$user_attribute = this.userAtrribute;
            contextVariables.$user_attribute_key = this.user_attr_select;
            nodeData.$context_variables = contextVariables;
            nodeData.$links = this.nodeUpdateDataAll['links'];
            
            nodeData.$next_node = this.nodeUpdateDataAll['next_node'];
            nodeData.$pos_top = this.nodeUpdateDataAll['pos_top'];
            nodeData.$pos_left = this.nodeUpdateDataAll['pos_left'];
            nodeData.$parent_nodes_type = this.nodeUpdateDataAll['parent_nodes_type'];
            if (this.dataMap) {
              for (let [key, value] of this.dataMap) {
                nodeData[key] = value;
              }
            }
            nodeJson.$node_data = nodeData;
            nodeJson.$flag = false;
            nodeJson['updateReq']=true;
            
            if(this.isGoalDefined) {
              let goalMap = this.setGoalArgs();
              nodeJson.$goal_name = goalMap.get("name")
              nodeJson.$goal_value = goalMap.get("value")
              nodeJson.$goal_unit = goalMap.get("unit")
      
              if(nodeJson.$goal_name == undefined || nodeJson.$goal_name == '' || nodeJson.$goal_name == null) {
                this.goalNameReq = true;
              } else {
                this.goalNameReq = false;
              }
            } else {
              nodeJson.$goal_name = null
              nodeJson.$goal_value = null
              nodeJson.$goal_unit = null
            } 

            if(this.checkMandatoryFields(nodeJson.$node_sub_type)){ 
              this.nodeServices.Loading=true;
              if(nodeJson['node_sub_type'] != "USER_INPUT"){
                this.nodeServices.updateNodeData(nodeJson, this.nodeUpdateData['id']).subscribe(
                  response => {
                    if(response["statusCode"] == 200){ 
                      
                      this.nodeServices.Loading=false;
                      let nodes:any = [] , chatObj={};
                      nodes = this.botDataService.dataToTransmit;
                      nodes.forEach((node , i)=>{
                        if(node.node_id == response["result"].node_id){
                          nodes.splice(i,1,response["result"]);
                        }
                      })
                      this.botDataService.dataToTransmit = nodes;
                      
                      chatObj["is_active"] = false;
                      this.botDataService.showOtherTabs(chatObj);
                      this.modelService.removeDialogComponentFromBody();
                      this.toastr.success('Node has been updated successfully');
                      if(this.config.data.node_type_Dummy){
                        this.commonService.customObservable(nodeJson);
                        
                        //this.commonService.setUpdateData(nodeJson);
                      }
                    }
                  },(error) => {
                  }); 
                }else{
                  if(this.config.data.node_type_Dummy){
                    nodeJson["chnageFromDummy"]=true;
                  }else{
                    nodeJson["chnageFromDummy"]=false;
                  }
                  this.commonService.setUpdateData(nodeJson);
                } 
              }
              
            }
            
            checkMandatoryFields(type){

              if(this.isGoalDefined && this.goalNameReq) {
                this.showvalidationMsg();
                return false;
              }

              this.val_error=false;
              
              if(type == 'IF_ELSE'){
                if(this.node_name_req && !this.val_error ){
                  this.showvalidationMsg();
                }else if(this.normalText == "Invalid Format for Normal Condition"){
                  this.ifelseStrCon = true; 
                  this.toastr.error("Invalid String");
                }else if(!this.ifElseLoopContinue){     
                  this.ifelseStrCon = true;   
                  this.showvalidationMsg();
                  return false;
                }else{
                  this.ifelseStrCon = false; 
                  this.ifElseLoopContinue = true;
                  return true;
                }    
              }else if(type == 'DELAY'){
                if(this.delayTotal == true){
                  this.toastr.error("Delay time can not be zero");
                  return false;
                }else{
                  return true;
                }
              }else if(type == 'OUTBOUND'){
                if(this.node_name_req && !this.val_error )
                {
                  this.showvalidationMsg()
                }
                if(!this.action_array.length)
                {
                  this.showvalidationMsg()
                  this.val_error=true;
                  if(this.show_response && !this.validation_select)
                  {
                    this.showvalidationMsg()
                    this.isResponse=true;
                  }
                  if(this.validation_select )
                  {
                    if(this.validation_select=='Regex' && !this.input_regex)
                    {
                      this.showvalidationMsg()
                      this.regex_req=true;
                      return false;
                    }
                  }
                  return false;
                }
                if(this.action_array.length)
                {
                  for (let index = 0; index < this.action_array.length; index++) {
                    if(this.action_array[index].data.text=='' ||this.action_array[index].data=='')
                    {
                      this.ifelseStrCon=true;
                      this.showvalidationMsg()
                      if(this.show_response && !this.validation_select)
                      {
                        this.showvalidationMsg()
                        this.isResponse=true;
                      }
                      if(this.validation_select )
                      {
                        if(this.validation_select=='Regex' && !this.input_regex)
                        {
                          this.showvalidationMsg()
                          this.regex_req=true;
                          return false;
                        }
                      }
                      return false;
                    }
                  }
                  if(this.show_response && !this.validation_select)
                  {
                    this.showvalidationMsg()
                    this.isResponse=true;
                    return false;
                  }
                }
                if(this.showError){
                  this.showvalidationMsg()
                  if(this.show_response && !this.validation_select)
                  {
                    this.showvalidationMsg()
                    this.isResponse=true;
                  }
                  if(this.validation_select )
                  {
                    if(this.validation_select=='Regex' && !this.input_regex)
                    {
                      this.showvalidationMsg()
                      this.regex_req=true;
                      return false;
                    }
                  }
                  return false;}
                  if(this.show_response && !this.validation_select)
                  {
                    this.showvalidationMsg()
                    this.isResponse=true;
                    return false;
                  }
                  if(this.validation_select )
                  {
                    if(this.validation_select=='Regex' && !this.input_regex)
                    {
                      this.showvalidationMsg()
                      this.regex_req=true;
                      return false;
                    }
                  }
                  if(this.userAtrribute)
                  {
                    if(this.user_attr_select=='' || this.user_attr_select==undefined || this.user_attr_select==null)
                    {
                      this.user_arttr_req=true; 
                      this.showvalidationMsg();
                    } else return true;
                  }
                  else{ return true;}
                  
                }else if(type == 'USER_INPUT'){
                  if(this.node_name_req && !this.val_error )
                  {
                    this.showvalidationMsg()
                  }
                  if(this.MessageSelect=='' || this.MessageSelect == 'Select Value' || this.MessageSelect==null)
                  {
                    this.showvalidationMsg()
                    this.val_error=true;
                    return false;
                  }
                  if(this.MessageSelect == 'Regex' && !this.input_regex)
                  {
                    this.regex_req=true;
                    return false;
                  }
                  if(this.MessageSelect == "Dropdown" || this.MessageSelect == "Buttons")
                  {
                    //    if(this.isOptionDuplicate){
                    //  // this.val_checkDuplicate=true;
                    
                    //     this.showInvelidvalidationMsg("Option value can not be duplicate")
                    
                    //   // this.toastr.error(" Please provide selection input")
                    //   return false;
                    //   }
                    if(this.isOptionEmpty){
                      // this.val_checkDuplicate=true;
                      
                      this.showInvelidvalidationMsg("Option name/value can not be empty")
                      
                      // this.toastr.error(" Please provide selection input")
                      return false;
                    }
                    
                    if(this.action_array.length== 0  && (this.args.id=='' || this.args.id==undefined) ){
                      this.val_error=true;
                      this.showvalidationMsg()
                      // this.toastr.error(" Please provide selection input")
                      return false;}
                      else{ return true;}  
                      
                    }
                    
                    
                    if(this.userAtrribute)
                    {
                      if(this.user_attr_select=='' || this.user_attr_select==undefined || this.user_attr_select==null)
                      {
                        this.user_arttr_req=true; 
                        this.showvalidationMsg();
                      }
                      else return true; 
                    }
                    else{ return true;} 
                  }else if(type == 'API' || type == 'WEBHOOK' ){
                    if(this.node_name_req && !this.val_error )
                    {
                      this.showvalidationMsg()
                    }
                    if((this.args.id=='' || this.args.id==undefined)){
                      this.val_error=true;
                      this.showvalidationMsg()
                      return false;}
                      else{ return true;}  
                    }else if(type == 'EVENT' || type == 'INCOMING_MSG' || type == 'WAIT_UNTIL' ){
                      if(this.node_name_req && !this.val_error )
                      {
                        this.showvalidationMsg()
                      }
                      if((!this.TextMessage || (this.MessageSelect=='' || this.MessageSelect == 'Select Value' || this.MessageSelect==null))){
                        this.showvalidationMsg()
                        this.val_error=true;
                        return false;}
                        else{ return true;}  
                      }else if(type == 'WORKFLOW_TRIGGER'){
                        if(this.node_name_req && !this.val_error )
                        {
                          this.showvalidationMsg()
                        }
                        if(this.data==''||this.data==null ||this.data==undefined){
                          this.val_error=true;
                          this.showvalidationMsg()
                          return false;}
                          else{ return true;}  
                        }else if(type == 'DEPARTMENT_SWITCHER'){
                          if(this.node_name_req && !this.val_error )
                          {
                            this.showvalidationMsg()
                          }
                          if(this.data==''||this.data==null ||this.data==undefined)
                          {
                            this.val_error=true;
                            this.showvalidationMsg()
                            return false;}
                            else{ return true;}  
                          }else if(type == 'STOP' || type=='IF_ELSE' ||type=='DELAY' ||type=='WAIT_UNTIL'|| type=='LOOP'){
                            if(this.node_name_req){
                              this.showvalidationMsg()
                              return false;}
                              else{ return true;}  
                            }else{ return true; }
                            
                            
                          }
                          showvalidationMsg()
                          {
                            this.toastr.error(" Highlighted fields are mandatory")
                          }

                          toggleGoal() {
                            this.isGoalDefined = !this.isGoalDefined
                          }
                        
                          changeGoalUnit(unit: string) {
                            this.goalUnit = unit;
                          }
                        
                          setGoalArgs() {
                            let goalMap = new Map();
                            goalMap.set("name", this.goalName);
                            if(this.goalValue != undefined && this.goalValue != '' && this.goalValue != null) {
                              goalMap.set("value", this.goalValue);
                            } else {
                              goalMap.set("value", 1)
                            }
                            if(this.goalUnit != undefined && this.goalUnit != 'Select Goal Unit' && this.goalUnit != null) {
                              goalMap.set("unit", this.goalUnit)
                            } else {
                              goalMap.set("unit", "UNIT")
                            }
                            return goalMap;
                          }
                        }
                        
                        
                        
