import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private userIdSource = new BehaviorSubject<number>(0);
  currentUser = this.userIdSource.asObservable();

  private updateDataSoruce=new BehaviorSubject<number>(0);
  updateDataBranching=this.updateDataSoruce.asObservable();

  private updateDataAfterChange=new BehaviorSubject<number>(0);
  updateDataAfter=this.updateDataAfterChange.asObservable();


  constructor() { }

  setData(data: any) {
    this.userIdSource.next(data)
  }

  setUpdateData(data:any){

    this.updateDataSoruce.next(data)

  }

   customObservable(data:any){

    this.updateDataAfterChange.next(data)

  }

}
