import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BotdataService {
 
  private messageSource = new BehaviorSubject(<any>[]);
  currentMessage = this.messageSource.asObservable();

  private otherTab = new BehaviorSubject(<any>{});
  entryNodeCreated = this.otherTab.asObservable();

  constructor() { }
  dataToTransmit:string;
  countConnection : number;

  changeMessage(data: string) {
    this.messageSource.next(data)
  }

  showOtherTabs(data : {}){
    this.otherTab.next(data);
  }

  




}
