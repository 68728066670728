import { links } from './links';
import { Data } from './Data';
import { nextNode } from './nextNode';
import { context_variables } from './context_variables';

export class NodeData{

    private pos_left : any ;
    private pos_top : any;
    private next_node : nextNode;
    private links : Array<links>;
    private context_variables : {};
    private data:{};
    private parent_nodes_type : []; 

    /**
     * Getter $parent_nodes_type
     * @return {[]}
     */
	public get $parent_nodes_type(): [] {
		return this.parent_nodes_type;
	}

    /**
     * Setter $parent_nodes_type
     * @param {[]} value
     */
	public set $parent_nodes_type(value: []) {
		this.parent_nodes_type = value;
	}
    
   /**
    * Getter $links
    * @return {Array<links>}
    */
    public get $links(): Array<links> {
        return this.links;
    }
   /**
    * Setter $links
    * @param {Array<links>} value
    */
    public set $links(value: Array<links>) {
        this.links = value;
    }

    /**
     * Getter $context_variables
     * @return {{}}
     */
	public get $context_variables(): {} {
		return this.context_variables;
	}

    /**
     * Setter $context_variables
     * @param {{}} value
     */
	public set $context_variables(value: {}) {
		this.context_variables = value;
	}
    


    /**
     * Getter $data
     * @return {{}}
     */
	public get $data(): {} {
		return this.data;
	}

    /**
     * Setter $data
     * @param {{}} value
     */
	public set $data(value: {}) {
		this.data = value;
	}
    
   

    /**
     * Getter $pos_left
     * @return {any }
     */
	public get $pos_left(): any  {
		return this.pos_left;
	}

    /**
     * Getter $pos_top
     * @return {any}
     */
	public get $pos_top(): any {
		return this.pos_top;
	}

    /**
     * Getter $next_node
     * @return {nextNode}
     */
	public get $next_node(): nextNode {
		return this.next_node;
	}

    /**
     * Setter $next_node
     * @param {nextNode} value
     */
	public set $next_node(value: nextNode) {
		this.next_node = value;
	}


   
   

    /**
     * Setter $pos_left
     * @param {any } value
     */
	public set $pos_left(value: any ) {
		this.pos_left = value;
	}

    /**
     * Setter $pos_top
     * @param {any} value
     */
	public set $pos_top(value: any) {
		this.pos_top = value;
	}

   

  
   
    
    


    

}