import { environment } from 'src/environments/environment';

export const GET_ALL_NODE: String = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/node/`;
export const SAVE_NODE: string =`${environment.baseUrl}/wb/api/v1/ezibot/workflow/nodes`;
export const DEL_NODE: string =`${environment.baseUrl}/wb/api/v1/ezibot/workflow/nodes/`;
export const UPDATE_NODE : string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/nodes/`;
export const GET_TEMPLATE : string = `${environment.baseUrl}/wb/api/v1/ezibot/rest/template`;
export const GET_CAMPAIGNS : string = `${environment.baseUrl}/wb/api/v1/ezibot/rest/campaigns`;
export const GET_APIS : string = `${environment.baseUrl}/wb/api/v1/ezibot/rest/apis`;
export const GET_WEBHOOK : string = `${environment.baseUrl}/wb/api/v1/ezibot/rest/webhook`;
export const GET_USER_PROPERTY : string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/user-property`;
export const GET_ALL_WORKFLOW : string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow`;
export const GET_DEPARTMENT : string = `${environment.baseUrl}/wb/api/v1/ezibot/rest/department`;
export const GET_NODE : string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/nodes/`;
export const GET_WORKFLOW :string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow`;
export const GET_CHAT_BOT :string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow`;
export const DEL_CHATBOT_BY_ID :string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow`;
export const PAUSE_WORKFLOW :string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/pause`;
export const RESUME_WORKFLOW :string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/resume`;
export const BOT_EDIT :string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/`;
export const UPDATE_WORKFLOW :string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow`;
export const GET_CHATBOT_LIST_USER :string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/users/`;
export const GET_COMMUNICATION_CHANNEL :string = `${environment.baseUrl}/wb/api/v1/ezibot/rest/channel/`;
export const GET_TOKEN :string = `${environment.baseUrl}/wb/authenticate`;
export const VALIDATE_TOKEN : string = `${environment.baseUrl}/wb/api/v1/ezibot/rest/validate-token`;
export const GET_CONTEXT_DETAILS : string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/context-details/`;
export const GET_DATA_OPTIONS: string = `${environment.baseUrl}/wb/api/v1/ezibot/workflow/nodes/data-options/`;
export const GET_DEPARTMENTS_NEW :string = `${environment.baseUrl}/wb/api/v1/ezibot/rest/organization/department/`;




