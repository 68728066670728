export class DelayTime {

private hour : any;
private minute : any;
private second : any;



    /**
     * Getter $hour
     * @return {any}
     */
	public get $hour(): any {
		return this.hour;
    }


    /**
     * Getter $minute
     * @return {any}
     */
	public get $minute(): any {
		return this.minute;
    }

    /**
     * Getter $second
     * @return {any}
     */
	public get $second(): any {
		return this.second;
    }


     /**
     * Setter $hour
     * @param {hour} value
     */
	public set $hour(value: any) {
		this.hour = value;
    }
    

      /**
     * Setter $minute
     * @param {minute} value
     */
	public set $minute(value: any) {
		this.minute = value;
    }
    

      /**
     * Setter $second
     * @param {second} value
     */
	public set $second(value: any) {
		this.second = value;
	}
}    