import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { tap , catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NodesService } from '../services/nodes/nodes.service';


@Injectable()

export class JwtInterceptor implements HttpInterceptor {
   orgId : any;
   public currentUserToken: any;
   isLoading:boolean=false;
   constructor(private authenticationService: AuthenticationService , private router : Router,private toastr: ToastrService,private nodeServices:NodesService) {
      this.orgId = "/"+sessionStorage.getItem("botifier-workflow-org-id");
  }

   ngOnInit(){    
   }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       this.authenticationService.currentUser.subscribe(user=> this.currentUserToken = user);
       if (this.currentUserToken) {
          request = request.clone({
               headers: request.headers.set('Authorization', 'Bearer '+JSON.parse(sessionStorage.getItem('token')))
           });
       }
       return next.handle(request).pipe( 
        tap(event => {
          if (event instanceof HttpResponse) {
          }
        }),    
        catchError((err :any) => {
            if(err instanceof HttpErrorResponse) {
                  if(err.status == 404){
                    this.router.navigate(["error"],{skipLocationChange: true});
                  }else if(err.status == 400){
                    this.toastr.error(err.error.message);
                    this.nodeServices.Loading=false;
                  }else if(err.status == 503){
                    console.log("Exception occured : "+ err.error.message);
                  }else if(err.status == 406){
                    sessionStorage.removeItem("botifier-workflow-bot-id");
                    sessionStorage.removeItem("channel_group_name");
                    sessionStorage.removeItem("bot_name");
                    this.router.navigate([this.orgId]); // when botId is not present in database
                  }
            }
            return throwError(err.statusText);
        })
       
       );
   }
}