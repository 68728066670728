import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DailogComponent } from './dialog/dailog.component';
import { InsertionDirective } from './dialog/insertion.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DailogComponent, InsertionDirective],
  entryComponents: [DailogComponent]
})
export class DialogModule {}