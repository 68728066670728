import { links } from '../beans/links';
export class endpointManage{

    static changeEndpoint(nodeLink,node_id){
        let positionLink=[];
        let  arrayOfBranching=Object.entries(nodeLink).map(([key, value]) => ({key,value}));
        if(arrayOfBranching.length != 0){
           let  nodeYLink=[];
            let positionLink=[];
            arrayOfBranching.forEach(element => {
                nodeYLink.push(element.value);
                
            });
            if(nodeYLink.length > 0){
                let position = 0.00;
                let increased_by = 1 / (nodeYLink.length + 1);
                for (let index = 0; index < nodeYLink.length; index++) {
                    if(nodeYLink[index] != null){
                    let element = nodeYLink[index]+"_top";
                    let yArray:any=[];
                    let Links = new links;
                    position = position + increased_by;
                    position = Math.round (position * 100) / 100;;
                    Links.$x = node_id+"_bottom_"+ position;
                    yArray.push(element)
                    Links.$y = yArray;
                    positionLink.push(Links); 
                    }
                }
                return positionLink;
            }
            return positionLink;

        }
         return positionLink;
    }

}